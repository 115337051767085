import { Snackbar, SnackbarContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppState } from "../../redux/types/app-state.type";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updateAppSnackbar } from "../../redux/reducers/app.reducer";
import { AppConstants } from "../../constants/app-constants";
import "./app-snackbar.css";
import { AppStrings } from "../../constants/string-constants";
import AssetProvider from "../../constants/app-assets";

const AppSnackbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const appState: AppState = useSelector((state: RootState) => state.app);
    const { showSnackbar, snackbarText } = appState;

    useEffect(() => {
        setIsOpen(showSnackbar);
    }, [showSnackbar]);

    const handleClose = () => {
        setIsOpen(false);
        dispatch(
            updateAppSnackbar({
                showSnackbar: false,
                snackbarText: AppStrings.DEFAULT_SNACKBAR_TEXT,
            })
        );
    };
    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={AppConstants.snackBarCloseDuration}
            onClose={handleClose}
            // message="Please fill both name and email."
            key={"snackbar"}
        >
            <div id="snackbar-content-wrapper">
                <img src={AssetProvider.tick} alt="tick-icon" />
                <p className="snackbar-text">{snackbarText}</p>
            </div>
        </Snackbar>
    );
};

export default AppSnackbar;
