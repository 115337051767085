import "./signup-button.css";

type SignupButtonProps = {
    text: string;
    click: Function;
};

export const SignupButton = ({ text, click }: SignupButtonProps) => {
    return (
        <button onClick={() => click()} className="signup-btn">
            <span className="signup-btn-text">{text}</span>
        </button>
    );
};
