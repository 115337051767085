import SubscribeForm from "../../../../components/convertkit-form/subscribe-form";
import Chip from "../../../../components/family-chip/family-chip";
import Spacer from "../../../../components/spacer";
import AssetProvider from "../../../../constants/app-assets";
import { isMobile } from "../../../../utils/helper-functions";
import FamilyComponent from "./components/family-component";
import "./hero-section.css";

export default function HeroSection() {
    return (
        <div id="hero-section">
            <div className="hero-section-wrapper">
                <div className="hero-section-content-wrapper">
                    <Chip />
                    <Spacer height="1rem" />
                    <span className="hero-text">
                        Simplify Life,
                        <br />
                        Amplify Love.
                    </span>
                    <Spacer height="1rem" />
                    <p className="hero-para-text">
                        Juggling work and life can be tough. Homie brings your
                        family{" "}
                        <span className="hero-para-highlight">closer,</span>{" "}
                        keeps you{" "}
                        <span className="hero-para-highlight">organized,</span>{" "}
                        and helps you create lasting{" "}
                        <span className="hero-para-highlight">memories.</span>
                    </p>
                    <Spacer height="3rem" />
                    <SubscribeForm
                        btnText="Join Waitlist"
                        width={isMobile() ? 100 : 85}
                    />
                    <Spacer height="28px" />
                    <div id="coming-soon-wrapper">
                        <p className="coming-soon-text">Soon On:</p>
                        <Spacer height="1rem" />
                        <div className="coming-soon-row">
                            <img
                                width={isMobile() ? "24px" : "28px"}
                                height={isMobile() ? "24px" : "28px"}
                                src={AssetProvider.appleIcon}
                                alt="iphone-logo"
                            />
                            <Spacer width="4px" />
                            <p className="coming-soon-logo-text">iPhone</p>
                            <Spacer width="24px" />
                            <img
                                width={isMobile() ? "24px" : "28px"}
                                height={isMobile() ? "24px" : "28px"}
                                src={AssetProvider.androidIcon}
                                alt="android-logo"
                            />
                            <Spacer width="4px" />
                            <p className="coming-soon-logo-text">Android</p>
                        </div>
                    </div>
                </div>
                <FamilyComponent />
            </div>
        </div>
    );
}
