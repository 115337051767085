import { v4 as uuidv4 } from "uuid";
import { LocalStoreKeys } from "../../constants/local-storage-keys";
import UserApi from "../../api/user/user.api";
import { AuthTokens } from "../../models/auth/auth-token.dto";
import { AppUser } from "../../models/user/user.dto";
import { addUser } from "../../redux/reducers/user.reducer";
import { TokenService } from "./auth/token.service";
import { addAnalyticsData } from "../../redux/reducers/analytics.reducer";
import appFirebaseService from "../external/app-firebase.service";
import { AnalyticsState } from "../../redux/types/analytics-state.type";
import { DeviceMetadata } from "../../models/analytics/device-metadata";
import { Location } from "react-router-dom";
import analyticService from "../external/analytic.service";
import MetadataApi from "../../api/meta/metadata.api";
import { DemographicMetadata } from "../../models/analytics/demographic-metadata";

export class PreloadService {
    public async registerUser(dispatch: Function) {
        var uid = localStorage.getItem(LocalStoreKeys.ANALYTICS_USER_ID);

        if (uid == null || uid == undefined) {
            uid = uuidv4();
            localStorage.setItem(LocalStoreKeys.ANALYTICS_USER_ID, uid);
            const data: AnalyticsState = {
                uid,
                scroll_depth: 0,
                visits: 1,
            };
            await appFirebaseService.createUserAnalyticsEntry(uid, data);
            await appFirebaseService.addUniqueVisitor();
            dispatch(addAnalyticsData(data));
            return;
        }

        var data: AnalyticsState | null =
            await appFirebaseService.fetchUserAnalytics(uid);

        // extreme corner case
        if (data == null) {
            data = { uid, visits: 1, scroll_depth: 0 };
            await appFirebaseService.createUserAnalyticsEntry(uid, data);
            dispatch(addAnalyticsData(data));
            return;
        }

        dispatch(addAnalyticsData({ ...data, visits: data.visits + 1 }));
        appFirebaseService.updateAnalyticInfo(uid, { visits: data.visits + 1 });
    }

    public async addMetadata(uid: string) {
        // add device metadata
        const deviceMetadata: DeviceMetadata = {
            device_pixel_ratio: window.devicePixelRatio,
            language: navigator.language,
            platform: navigator.platform,
            user_agent: navigator.userAgent,
            device_height: window.outerHeight,
            device_width: window.outerWidth,
        };
        await appFirebaseService.updateDeviceMetadata(uid, deviceMetadata);

        // add degraphic metadata
        const demographicMetadata: DemographicMetadata | null =
            await MetadataApi.getInstance().fetchDemographicMetadata();
        if (demographicMetadata) {
            await appFirebaseService.updateDemographicMetadata(
                uid,
                demographicMetadata
            );
        }
    }

    public async initAppLoad(dispatch: Function, location: Location<any>) {
        await this.registerUser(dispatch);

        const uid = localStorage.getItem(LocalStoreKeys.ANALYTICS_USER_ID);
        if (uid) {
            await appFirebaseService.setUpPresenceSystem(uid);
            await this.addMetadata(uid);
            await this.addReferral(location, dispatch);
        }
        await appFirebaseService.addWebsiteView();
    }

    public addReferral(location: Location<any>, dispatch: Function) {
        const queryParams = new URLSearchParams(location.search);

        const getValueFromParam = (paramName: string) => {
            return queryParams.get(paramName);
        };

        const referralSource: string | null = getValueFromParam("utm_source"); // Example usage

        analyticService.registerReferral(referralSource, dispatch);

        const referrerUrl = document.referrer;
        const uid = localStorage.getItem(LocalStoreKeys.ANALYTICS_USER_ID);
        if (referrerUrl && referrerUrl.length > 0 && uid) {
            const urlWithoutSlashes = referrerUrl
                .replace(/\//g, "%")
                .replaceAll(".", "_dot_");
            appFirebaseService.registerReferralSites(uid, urlWithoutSlashes);
        }
    }

    public async loadAuth(dispatch: Function) {
        const creds: AuthTokens | null =
            await new TokenService().getAuthCreds();

        // check if tokens exists
        if (!creds) return;

        // fetch user data
        const appUser: AppUser | null = await UserApi.getInstance().fetchUser();

        if (!appUser) {
            return;
        }

        dispatch(addUser({ user: appUser }));
    }
}
