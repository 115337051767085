import { Link } from "react-router-dom";
import Spacer from "../../../../components/spacer";
import AssetProvider from "../../../../constants/app-assets";
import "./footer-section.css";
import AppRoutes from "../../../../constants/routes";
import Helper from "../../../../utils/helper-functions";

export default function FooterSection() {
    return (
        <div id="footer-section">
            <div id="footer-wrapper">
                <img
                    width={"150px"}
                    src={AssetProvider.homie}
                    alt="footer-logo"
                />
                <Spacer height="5vh" />
                <Spacer height="1px" width="100%" bg="#E3E3EA" />
                <Spacer height="4vh" />
                <div id="policy-row">
                    <span id="copyright-text">Copyright © 2024 Homie</span>
                    <div id="policy-wrapper">
                        <Link
                            onClick={() =>
                                Helper.addInteration("terms_conditions_click")
                            }
                            to={AppRoutes.termsAndConditions}
                        >
                            <span className="footer-text">
                                Terms & Conditions
                            </span>
                        </Link>
                        <Spacer width="35px" />
                        <Link
                            onClick={() =>
                                Helper.addInteration("privacy_ploicy_click")
                            }
                            to={AppRoutes.privacyPolicy}
                        >
                            <span className="footer-text">Privacy Policy</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
