import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    showAppDialog,
    updateAppSnackbar,
} from "../../redux/reducers/app.reducer";
import { RootState } from "../../redux/store";
import { AppState } from "../../redux/types/app-state.type";
import "./early-access-dialog.css";
import AssetProvider from "../../constants/app-assets";
import AppAnalyticService from "../../logic/external/analytic.service";
import { LocalStoreKeys } from "../../constants/local-storage-keys";
import Spacer from "../spacer";
import Modal from "@mui/material/Modal";
import { AppStrings } from "../../constants/string-constants";
import axios from "axios";
import Helper from "../../utils/helper-functions";
import appFirebaseService from "../../logic/external/app-firebase.service";

const EarlyAccessDialog = () => {
    const emailInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const appState: AppState = useSelector((state: RootState) => state.app);
    const { showDialog } = appState;
    const dispatch = useDispatch();

    const handleModalClose = () => {
        setIsModalVisible(false);
        dispatch(showAppDialog({ showDialog: false }));
    };

    useEffect(() => {
        setIsModalVisible(showDialog);
    }, [showDialog]);

    const handleSubmit = (event: any) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const emailConfirmed = localStorage.getItem(
            LocalStoreKeys.EMAIL_CONFIRM
        );
        const userData = localStorage.getItem(LocalStoreKeys.USER_DATA);
        const email = emailInputRef.current?.value;

        if (emailConfirmed && userData) {
            const { email: savedEmail } = JSON.parse(userData);
            if (savedEmail == email) {
                triggerSnackbar(AppStrings.ALREADY_ON_WAITLIST);
                return;
            }
        }

        const name = nameInputRef.current?.value;

        if (userData) {
            const { name: savedName, email: savedEmail } = JSON.parse(userData);
            if (name == savedName && email == savedEmail) {
                triggerSnackbar(AppStrings.AWAITING_EMAIL_CONFIRMATION);
                return;
            }
        }

        const uid = localStorage.getItem(LocalStoreKeys.ANALYTICS_USER_ID);
        if (
            email &&
            name &&
            Helper.validateEmail(email) &&
            name.length > 2 &&
            uid
        ) {
            AppAnalyticService.addFormDetailsToRtdb(uid, email, name);

            // Make a POST request using Axios
            axios
                .post(
                    "https://app.convertkit.com/forms/6291505/subscriptions",
                    {
                        fields: { first_name: name },
                        email_address: email,
                    }
                )
                .then((response) => {
                    // Handle the response if needed
                    triggerSnackbar(AppStrings.WAITLIST_JOIN_SNACKBAR_TEXT);
                })
                .catch((error) => {
                    // Handle errors
                    appFirebaseService.submitIssue(uid, {
                        type: "convetkit_api_failure",
                        name,
                        email,
                    });
                    console.error("Convertkit Error:", error);
                    triggerSnackbar(AppStrings.UNABLE_TO_JOIN);
                });
        } else {
            triggerSnackbar(AppStrings.REQUIRED_FIELDS_SNACKBAR_TEXT);
        }
    };

    function triggerSnackbar(msg: string) {
        dispatch(
            updateAppSnackbar({
                showSnackbar: true,
                snackbarText: msg,
            })
        );
    }

    return (
        <Modal
            open={isModalVisible}
            onClose={handleModalClose}
            // onCancel={handleModalClose}
            // onOk={handleClick}

            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="dialog-wrapper">
                <div id="cancel-circle-wrapper">
                    <img
                        onClick={handleModalClose}
                        id="early-access-close-button"
                        src={AssetProvider.cancelCircle}
                        alt="cancel-circle"
                    />
                </div>
                <div id="early-access-modal-content-wrapper">
                    <img
                        width={"100px"}
                        src={AssetProvider.homie}
                        alt="dialog-logo"
                    />
                    <Spacer height="36px" />
                    <span id="modal-title">Get Early Access</span>
                    <Spacer height="0.5rem" />
                    <p id="modal-content">
                        Join waitlist for exclusive updates, new arrivals &
                        inside only discounts!
                    </p>
                    <Spacer height="28px" />
                    <script src="https://f.convertkit.com/ckjs/ck.5.js" />
                    <form
                        id="modal-form"
                        action="https://app.convertkit.com/forms/6291505/subscriptions"
                        method="post"
                        data-sv-form="6291505"
                        data-uid="b246ded583"
                        data-format="inline"
                        data-version="5"
                        data-options='{"settings":{"analytics":{"google":null,"fathom":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null,"googletagmanager":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic"},"recaptcha":{"enabled":false},"return_visitor":{"action":"custom_content","custom_content":"Thank you for trusting us, we will keep you posted of all future updates &amp; launches!"},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
                        min-width="400 500 600 700 800"
                        onSubmit={handleSubmit}
                    >
                        <div data-style="clean">
                            <ul data-element="errors" data-group="alert"></ul>
                            <div
                                id="modal-form-wrapper"
                                data-element="fields"
                                data-stacked="false"
                            >
                                <input
                                    id="modal-form-name-input"
                                    ref={nameInputRef}
                                    aria-label="First Name"
                                    name="fields[first_name]"
                                    placeholder="Enter Full Name"
                                    type="text"
                                />
                                <Spacer height="12px" />
                                <input
                                    id="modal-form-email-input"
                                    ref={emailInputRef}
                                    name="email_address"
                                    aria-label="Enter your e-mail"
                                    placeholder="Enter E-mail"
                                    required={true}
                                    type="email"
                                />
                                <Spacer height="36px" />
                                <button
                                    onClick={handleSubmit}
                                    id="modal-form-btn"
                                    data-element="submit"
                                >
                                    <div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <span id="subscribe-form-btn-text">
                                        Join Our Waitlist
                                    </span>
                                </button>
                            </div>
                            <div></div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default EarlyAccessDialog;
