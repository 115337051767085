import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase-config";
import { AppConstants } from "../../constants/app-constants";
import { LocalStoreKeys } from "../../constants/local-storage-keys";
import { AppStrings } from "../../constants/string-constants";
import { updateAppSnackbar } from "../../redux/reducers/app.reducer";
import Helper from "../../utils/helper-functions";
import { LoggingService } from "../internal/logging.service";
import appFirebaseService from "./app-firebase.service";

class AppAnalyticService {
    private static instance: AppAnalyticService;

    private constructor() {}

    public static getInstance(): AppAnalyticService {
        if (!AppAnalyticService.instance) {
            AppAnalyticService.instance = new AppAnalyticService();
        }

        return AppAnalyticService.instance;
    }

    public firebaseEventLog(eventName: string, obj: any) {
        logEvent(analytics, eventName, obj);
    }

    public addFormDetailsToRtdb(uid: string, email: string, name: string) {
        const userData = { email, name };
        localStorage.setItem(
            LocalStoreKeys.USER_DATA,
            JSON.stringify(userData)
        );
        appFirebaseService.updateAnalyticInfo(uid, userData);
    }

    public updateScrollPercentage(uid: string, scrollPercentage: number) {
        if (!uid) {
            new LoggingService().logErrors(
                "No uid found while updating scroll percentage",
                {}
            );
            return;
        }

        if (scrollPercentage > 90) {
            logEvent(analytics, "full_page_scroll");
        }
        appFirebaseService.updateScrollPercentage(uid, scrollPercentage);
    }

    public registerReferral(source: string | null, dispatch: Function) {
        if (source == null) {
            source = "direct";
        }

        const uid = localStorage.getItem(LocalStoreKeys.ANALYTICS_USER_ID);
        if (source == AppConstants.emailConfirmationKey) {
            dispatch(
                updateAppSnackbar({
                    showSnackbar: true,
                    snackbarText: AppStrings.EMAIL_CONFIRMATION_SNACKBAR_TEXT,
                })
            );
            localStorage.setItem(LocalStoreKeys.EMAIL_CONFIRM, "YES");
            if (uid) {
                appFirebaseService.updateAnalyticInfo(uid, {
                    email_verified: true,
                });
            }
            return;
        } else if (source == AppConstants.waitlistJoinKey) {
            dispatch(
                updateAppSnackbar({
                    showSnackbar: true,
                    snackbarText: AppStrings.WAITLIST_JOIN_SNACKBAR_TEXT,
                })
            );
            return;
        }

        this.firebaseEventLog("referred", { source });
        if (uid) {
            appFirebaseService.registerReferral(uid, source);
        }
    }
}
export default AppAnalyticService.getInstance();
