export class AppConstants {
    static API_RETRY_LIMIT: number = 3;

    static user: string = "user";
    static analytic: string = "analytic";
    static app: string = "app";
    static prodEnv: string = "prod";
    static devEnv: string = "dev";
    static emailConfirmationKey: string = "email_confirmation";
    static waitlistJoinKey: string = "waitlist_joined";
    static snackBarCloseDuration: number = 5 * 1000; // 5 secs;
}
