import { isMobile } from "../../../../utils/helper-functions";
import DesktopImpactCols from "./components/desktop-imapact-cols";
import ImpactHeader from "./components/impact-header";
import MobileImpactCols from "./components/mobile-impact-cols";
import "./impact-section.css";

export default function ImpactSection() {
    return (
        <div id="impact-section">
            <div id="impact-content-wrapper">
                <ImpactHeader />
                {isMobile() ? <MobileImpactCols /> : <DesktopImpactCols />}
            </div>
        </div>
    );
}
