import {
    get,
    onDisconnect,
    ref,
    runTransaction,
    set,
    update,
} from "firebase/database";
import { database } from "../../config/firebase-config";
import { DeviceMetadata } from "../../models/analytics/device-metadata";
import { AnalyticsState } from "../../redux/types/analytics-state.type";
import { DemographicMetadata } from "../../models/analytics/demographic-metadata";

class AppFirebaseService {
    private static instance: AppFirebaseService;
    private analyticUserRef = "analytics/users";

    private constructor() {}

    public static getInstance(): AppFirebaseService {
        if (!AppFirebaseService.instance) {
            AppFirebaseService.instance = new AppFirebaseService();
        }

        return AppFirebaseService.instance;
    }

    public submitFeedback(uid: string, feedbackObj: any) {
        const feedbackRef = ref(database, `feedbacks/${uid}`);
        update(feedbackRef, feedbackObj);
    }

    public submitIssue(uid: string, issueObj: any) {
        const issueRef = ref(database, `issues/${uid}`);
        update(issueRef, issueObj);
    }

    public async addUniqueVisitor() {
        const statusRef = ref(database, `analytics/stats/visitors`);
        runTransaction(statusRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
        await this.addDailyActivity("new_user");
    }

    public async addWebsiteView() {
        const statusRef = ref(database, `analytics/stats/views`);
        runTransaction(statusRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
        await this.addDailyActivity("views");
    }

    public addDailyActivity(activityType: string) {
        const time: string = new Date().toISOString().split("T")[0];
        const interactionRef = ref(
            database,
            `analytics/daily-activity/${time}/${activityType}`
        );
        runTransaction(interactionRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
    }
    public addUserInteration(uid: string, interactionType: string) {
        // const allowedInteraction: string[] = [];
        // if (!allowedInteraction.includes(interactionType)) return;

        const interactionRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/interactions/${interactionType}`
        );
        runTransaction(interactionRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
    }

    public registerReferralSites(uid: string, source: string) {
        const referralSiteRef = ref(
            database,
            `analytics/referral-site/${source}`
        );
        runTransaction(referralSiteRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
        const userReferralSiteRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/referral-site/${source}`
        );
        runTransaction(userReferralSiteRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
    }

    public registerReferral(uid: string, source: string) {
        const referralRef = ref(database, `analytics/referral/${source}`);
        runTransaction(referralRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
        const userReferralRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/referral/${source}`
        );
        runTransaction(userReferralRef, (currentValue) => {
            if (currentValue === null) {
                return 1; // Set initial value to 1 if it doesn't exist
            }
            return currentValue + 1;
        });
    }

    public async fetchUserAnalytics(
        uid: string
    ): Promise<AnalyticsState | null> {
        const analyticsRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/info`
        );
        const snapshot = await get(analyticsRef);
        if (snapshot.exists()) {
            const data = snapshot.val();
            const analyticsState: AnalyticsState = { ...data };
            return analyticsState;
        }
        return null;
    }

    public createUserAnalyticsEntry(uid: string, obj: any) {
        const analyticsRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/info`
        );
        update(analyticsRef, obj);
    }

    public updateScrollPercentage(uid: string, scrollPercentage: number) {
        const analyticsRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/info`
        );
        update(analyticsRef, { scroll_depth: scrollPercentage });
    }

    public updateAnalyticInfo(uid: string, obj: any) {
        const analyticsRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/info`
        );
        update(analyticsRef, obj);
    }

    public updateDeviceMetadata(uid: string, deviceMetadata: DeviceMetadata) {
        const deviceMetadataRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/device_metadata`
        );
        update(deviceMetadataRef, deviceMetadata);
    }

    public updateDemographicMetadata(
        uid: string,
        demographicMetadata: DemographicMetadata
    ) {
        const demographicMetadataRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/demographic_metadata`
        );
        update(demographicMetadataRef, demographicMetadata);
    }

    // Function to update last active timestamp
    public updateLastActiveTimestamp(id: string) {
        const lastActiveRef = ref(database, `analytics/${id}/last_active`);
        const time: string = new Date().toISOString();
        set(lastActiveRef, time);
    }

    // Function to set up presence system
    public setUpPresenceSystem(uid: string) {
        const userStatusRef = ref(
            database,
            `${this.analyticUserRef}/${uid}/status`
        );

        // Set user status to 'online' when user connects
        update(userStatusRef, { visibility: "online" });

        // // Listen for user disconnect
        // onDisconnect(userStatusRef).set("offline");

        // Update last active timestamp when user disconnects
        const time: string = new Date().toISOString();
        onDisconnect(userStatusRef).cancel(); // Cancel any existing onDisconnect operation
        onDisconnect(userStatusRef).update({
            visibility: "offline",
            last_active: time,
        });
    }
}
export default AppFirebaseService.getInstance();
