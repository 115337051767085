// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { AppConstants } from "../constants/app-constants";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseProdConfig = {
    apiKey: "AIzaSyCad2aGvprkVmwC4rwbM9QzitSdhNJHTUo",
    authDomain: "homie-website-7366a.firebaseapp.com",
    projectId: "homie-website-7366a",
    databaseURL:
        "https://homie-website-7366a-default-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "homie-website-7366a.appspot.com",
    messagingSenderId: "378606544490",
    appId: "1:378606544490:web:4bf5a9102cf99cb12c4c13",
    measurementId: "G-DFKV97GXR9",
};

const firebaseDevConfig = {
    apiKey: "AIzaSyAErZ_BdDz-jwb4mJqR0hYxKUw4Y21NL_Q",
    authDomain: "homie-7f7c6.firebaseapp.com",
    projectId: "homie-7f7c6",
    databaseURL:
        "https://homie-7f7c6-default-rtdb.asia-southeast1.firebasedatabase.app",
    storageBucket: "homie-7f7c6.appspot.com",
    messagingSenderId: "977660696851",
    appId: "1:977660696851:web:78093ce04360c655c622e6",
    measurementId: "G-ET5XVR0KBN",
};

// Initialize Firebase
const isProdEnv: boolean = process.env.REACT_APP_ENV == AppConstants.prodEnv;
const app = initializeApp(isProdEnv ? firebaseProdConfig : firebaseDevConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const appAuth = getAuth(app);
const db = getFirestore(app);
const googleAuth = new GoogleAuthProvider();
export { db, database, appAuth, analytics, googleAuth };
