import Spacer from "../../../../../components/spacer";
import AssetProvider from "../../../../../constants/app-assets";
import ImpactChip from "./impact-chip";
import "../impact-section.css";

export default function DesktopImpactCols() {
    return (
        <>
            <div className="impact-col">
                <ImpactChip
                    text="Strengthen Bond"
                    bgColor="#FFE8E8"
                    icon={AssetProvider.lock}
                    outlineColor="#F68B8B"
                    textColor="#9C3131"
                />
                <Spacer height="0.5rem" />
                <img src={AssetProvider.family3} />
            </div>
            <Spacer width="0.5rem" />
            <div className="impact-col">
                <img src={AssetProvider.family4} />
                <Spacer height="0.5rem" />
                <ImpactChip
                    text="Stay Connected"
                    bgColor="#EFE3FF"
                    icon={AssetProvider.connected}
                    outlineColor="#A782CB"
                    textColor="#250049"
                />
            </div>
            <Spacer width="0.5rem" />
            <div className="impact-col">
                <ImpactChip
                    text="Save Time"
                    bgColor="#FFFCEE"
                    icon={AssetProvider.timer}
                    outlineColor="#EABC46"
                    textColor="#A47600"
                />
                <Spacer height="0.5rem" />
                <img src={AssetProvider.family5} />
            </div>
            <Spacer width="0.5rem" />
            <div className="impact-col">
                <ImpactChip
                    text="Secured Data"
                    bgColor="#ECF9F8"
                    icon={AssetProvider.lock2}
                    outlineColor="#3CBFB5"
                    textColor="#25756E"
                />
                <Spacer height="0.5rem" />
                <img src={AssetProvider.family6} />
                <Spacer height="0.5rem" />
                <ImpactChip
                    text="Less Stressed"
                    bgColor="#F4F7EE"
                    icon={AssetProvider.lock3}
                    outlineColor="#8DB255"
                    textColor="#566D34"
                />
                <Spacer height="0.5rem" />
                <ImpactChip
                    text="Organized"
                    bgColor="#FFF1EA"
                    icon={AssetProvider.organized}
                    outlineColor="#FF7F50"
                    textColor="#9C4D31"
                />
            </div>
        </>
    );
}
