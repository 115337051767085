import React from "react";

interface FeatureIconProps {
    icon: any;
    isSelected: boolean;
}

const FeatureIcon: React.FC<FeatureIconProps> = ({
    icon,
    isSelected,
}: FeatureIconProps) => {
    return (
        <div className={`feature-icon`}>
            <img src={icon} alt={icon.toString()} />
        </div>
    );
};

export default FeatureIcon;
