import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import AppRoutes from "./constants/routes";
import { PreloadService } from "./logic/internal/preload.service";
import { RootState } from "./redux/store";
import { UserState } from "./redux/types/user-state.type";
import HomeScreen from "./screens/home/home-screen";
import PrivacyPolicy from "./screens/policies/privacy-policy";
import TermsAndConditions from "./screens/policies/terms-and-conditions";
import ScrollToTopOnMount from "./utils/scroll-to-top";
import EarlyAccessDialog from "./components/dialog/early-access-dialog";
import AppSnackbar from "./components/snackbar/app-snackbar";
import WaitlistScreen from "./screens/waitlist/waitlist-screen";

function App() {
    const dispatch = useDispatch();
    const [authenticated, setAuthenticated] = useState(false);
    const userState: UserState = useSelector((state: RootState) => state.user);
    const location = useLocation();

    useEffect(() => {
        setAuthenticated(userState.isSignedIn);
    }, [userState]);

    useEffect(() => {
        new PreloadService().initAppLoad(dispatch, location);
    }, []);

    return (
        <div className="App">
            <ScrollToTopOnMount />
            <EarlyAccessDialog />
            <AppSnackbar />
            <Routes>
                {!authenticated && (
                    <Route path={AppRoutes.home} element={<HomeScreen />} />
                )}
                <Route
                    path={AppRoutes.privacyPolicy}
                    element={<PrivacyPolicy />}
                />
                <Route
                    path={AppRoutes.termsAndConditions}
                    element={<TermsAndConditions />}
                />
                <Route
                    path={AppRoutes.termsAndConditions}
                    element={<TermsAndConditions />}
                />
                <Route path={AppRoutes.waitlist} element={<WaitlistScreen />} />
                <Route
                    path="*"
                    element={
                        <Navigate
                            to={authenticated ? AppRoutes.home : AppRoutes.home}
                        />
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
