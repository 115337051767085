import { LocalStoreKeys } from "../../../constants/local-storage-keys";
import { AuthTokens } from "../../../models/auth/auth-token.dto";

export class TokenService {
    public getAuthCreds(): AuthTokens | null {
        const authCredsString = localStorage.getItem(LocalStoreKeys.AUTH_CREDS);
        if (authCredsString == null) {
            return null;
        }
        const authTokens: AuthTokens = JSON.parse(authCredsString);
        return authTokens;
    }
}
