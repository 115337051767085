import { useEffect, useState } from "react";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsState } from "../redux/types/analytics-state.type";
import { updateScrollPercentage } from "../redux/reducers/analytics.reducer";
import analyticService from "../logic/external/analytic.service";

type ScrollState = {
    pageLengthCovered: number;
    uid: string;
    analyticsScrollPercentage: number;
};

const ScrollTrack = () => {
    const dispatch = useDispatch();
    const [scrollState, setScrollState] = useState<ScrollState>({
        uid: "",
        analyticsScrollPercentage: 0,
        pageLengthCovered: 0,
    });

    const analyticsState: AnalyticsState = useSelector(
        (state: RootState) => state.analytic
    );

    useEffect(() => {
        setScrollState((prevScrollState: ScrollState) => {
            return {
                ...prevScrollState,
                uid: analyticsState.uid,
                analyticsScrollPercentage: analyticsState.scroll_depth,
            };
        });
    }, [analyticsState]);

    useEffect(() => {
        const getScrollPercentage = () => {
            const documentHeight = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight
            );
            const windowHeight = window.innerHeight;
            const scrollTop = window.pageYOffset;

            const scrolledPercentage = Math.round(
                (scrollTop / (documentHeight - windowHeight)) * 100
            );
            return scrolledPercentage;
        };

        const handleScroll = () => {
            const scrollPercentage = getScrollPercentage();
            setScrollState((prevScrollState: ScrollState) => {
                const { analyticsScrollPercentage, pageLengthCovered, uid } =
                    prevScrollState;
                if (pageLengthCovered < scrollPercentage - 3) {
                    updateScrollMilestone(
                        analyticsScrollPercentage,
                        uid,
                        scrollPercentage
                    );
                    return {
                        ...prevScrollState,
                        pageLengthCovered: scrollPercentage,
                    };
                }
                return prevScrollState;
            });
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const updateScrollMilestone = (
        analyticsScrollPercentage: number,
        uid: string,
        scrollPercentage: number
    ) => {
        dispatch(updateScrollPercentage({ scrollPercentage }));

        if (analyticsScrollPercentage < scrollPercentage) {
            analyticService.updateScrollPercentage(uid, scrollPercentage);
        }
    };

    return <div />;
};

export default ScrollTrack;
