import React from "react";
import "./policies-style.css";
import { Link } from "react-router-dom";
import Helper from "../../utils/helper-functions";
import AppRoutes from "../../constants/routes";

export default function TermsAndConditions() {
    return (
        <div style={{ padding: "5vh 5vw" }}>
            <h1>
                <span style={{ color: "#272727", fontSize: "25.5pt" }}>
                    TERMS &amp; CONDITIONS
                </span>
            </h1>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    Welcome to HomieApp, the "Service." The following HomieApp
                    Terms and Conditions are between you and Homie &amp; Co SAS,
                    with headquarters at 16, rue Théodore de Banville, 75017,
                    Paris, France, including its affiliates ("Homie &amp; Co")
                    and constitute a legal agreement that governs your use of
                    the Service. The Service is comprised of web pages
                    containing information and resources provided by Homie &amp;
                    Co and certain third party content providers. The HomieApp
                    Terms and Conditions only govern your use of the Service. It
                    does not apply to use of any other Homie &amp; Co websites,
                    products, software or services, and it does not modify or
                    alter other agreements you may have, or enter later, with
                    Homie &amp; Co. Homie &amp; Co offers you access to the
                    Service conditioned on your acceptance of these HomieApp
                    Terms and Conditions, including your agreement to the&nbsp;
                </span>
                <Link to={AppRoutes.privacyPolicy}>
                    <a>
                        <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                            Privacy Statement
                        </span>
                    </a>
                </Link>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    &nbsp;and the HomieApp&nbsp;
                </span>
                <a href="#conduct">
                    <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                        Code of Conduct
                    </span>
                </a>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    , each as amended from time to time, which are part of and
                    incorporated into these HomieApp Terms and Conditions
                    (collectively, the "Terms") and apply to your use of the
                    Service. "You" means an individual who accesses the Service
                    or any services available through the Service ("Services").
                </span>
            </p>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    Homie &amp; Co’s Privacy Statement contains information
                    about Homie &amp; Co’s practices for collection and use of
                    information about you and your use of the Service. You agree
                    that Homie &amp; Co, complying with its&nbsp;
                </span>
                <Link to={AppRoutes.privacyPolicy}>
                    <a>
                        <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                            Privacy Statement
                        </span>
                    </a>
                </Link>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    , may collect, use, disclose, transmit, process and store
                    information related to you and your account for Services, to
                    operate and provide the Service. You have the right to
                    update, amend and delete your personal information in
                    accordance with the Privacy Statement. Personal information
                    collected through the Service may be stored and processed in
                    Europe and any other country in which Homie &amp; Co or its
                    service providers maintain facilities. By using the Service,
                    you consent to the transfer, recording, and storage of
                    information outside of the country where you reside. While
                    in another jurisdiction for processing, the information may
                    be accessed by the courts, law enforcement and national
                    security authorities. Please review the&nbsp;
                </span>
                <Link to={AppRoutes.privacyPolicy}>
                    <a>
                        <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                            Privacy Statement
                        </span>
                    </a>
                </Link>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>.</span>
            </p>
            <br />
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    Please read these Terms carefully before accessing or using
                    the Service. By clicking a box that indicates you agree to
                    the Terms, or by otherwise accessing or using the Service
                    (where agreement by such access or use is permitted by law),
                    you agree to be bound by these Terms. If you do not wish to
                    be bound by these Terms, do not agree to the Terms or access
                    or use the Service. The Service does not target and is not
                    intended for children under the age of 13. If you are under
                    the age of 13 you should not access the Service or Services
                    and should not provide to Homie &amp; Co any information
                    about yourself, including your name, address, telephone
                    number, or email address (directly, or on site bulletin
                    boards, blogs, etc.). You must be at least 13 years of age
                    or older to register. By registering or using the Service,
                    you certify that you are 13 years of age or older. If you
                    are 13 years of age or older but under the age of majority
                    according to local law (18 in most countries), before you
                    decide whether or not to access this Service and to share
                    Personal Information with Homie &amp; Co, you should review
                    this&nbsp;
                </span>
                <Link to={AppRoutes.privacyPolicy}>
                    <a>
                        <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                            Privacy Statement
                        </span>
                    </a>
                </Link>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    &nbsp;with your parent or guardian to make sure that you and
                    your parent or guardian understand it. Parents or guardians
                    are advised to supervise their children while using the
                    Internet and mobile and other devices. Homie &amp; Co
                    reserves the right to change these Terms at any time in
                    accordance with&nbsp;
                </span>
                <a href="#section18">
                    <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                        Section 18
                    </span>
                </a>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>.</span>
            </p>
            <ol>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Additional terms.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;may be presented to you in connection with
                            your use of the Service or particular feature of the
                            Service, or the downloading of material from the
                            Service or through the Service. You agree to comply
                            with any additional terms, which are incorporated in
                            these Terms by reference. If you do not agree to the
                            additional terms, do not use or purchase the
                            particular features to which the additional terms
                            apply (if the terms are limited to specific
                            features) or stop using the Service and terminate
                            any related Service account. You may also be subject
                            to additional terms and conditions from third
                            parties that may apply when you purchase products or
                            services or use or download software from third
                            parties.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Passwords;
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Attribution of Electronic Acts to You. This
                            Service requires you to register with a login and
                            password. If you register, you must provide Homie
                            &amp; Co with current, complete and accurate
                            information. You agree to maintain and are
                            responsible for maintaining the confidentiality of
                            your password and login. You agree that if your
                            login and password are used on the Service, Homie
                            &amp; Co may attribute that use to you and you will
                            be legally bound by it and responsible for any and
                            all activities that occur under your account. We
                            encourage you to use “strong” passwords (passwords
                            that use a combination of upper and lower case
                            letters, numbers and symbols) with your account. You
                            agree to notify Homie &amp; Co immediately of any
                            unauthorized use of your account or any other breach
                            of security at the email address set forth&nbsp;
                        </span>
                        <a href="#section19">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                Section 19
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            .
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Service Use.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Access to the Service is provided on a
                            temporary basis and you may use the Service when it
                            is available. The Service may not always be
                            available. There may be interruptions or extended
                            downtimes. Homie &amp; Co reserves the right to
                            change or remove, and restrict or block access to,
                            all or any part of the Service (including any
                            features thereof) at any time without notice.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Your Conduct.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;The Service may contain communication features
                            (such as blogs, support forums, etc.) designed to
                            enable you to communicate with Homie &amp; Co and
                            other members. You agree to use the Service only to
                            post, send and receive messages and material that
                            are appropriate and related to the purpose of the
                            Service. You must not misuse the Service by
                            knowingly introducing viruses, trojans, worms, logic
                            bombs or other material which is malicious or
                            technically harmful. You must not attempt to gain
                            unauthorized access to the Service, or any servers,
                            computers, or any data bases connected with the
                            Service. You must not attach the Service via a
                            denial-of-service attack, or a distributed
                            denial-of-service attack. You agree that when using
                            the Service, you will not engage or attempt to
                            engage in any improper uses, as set out above and
                            also as set out in the&nbsp;
                        </span>
                        <a href="#conduct">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                Code of Conduct
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            . If you breach this section you may commit a
                            criminal offence. Homie &amp; Co will report any
                            such breach to the relevant law enforcement
                            authorities and Homie &amp; Co will cooperate with
                            those authorities by disclosing your name and any
                            other relevant information that Homie &amp; Co holds
                            about you. In the event of such a breach, your right
                            to use the Service will cease immediately. If Homie
                            &amp; Co suspects violations of any of this Section,
                            Homie &amp; Co may institute legal action and
                            cooperate with law enforcement authorities in
                            bringing legal proceedings against violators. You
                            agree to cooperate with Homie &amp; Co in
                            investigating suspected violations. Homie &amp; Co
                            reserves the right to install, implement, manage and
                            operate one or more software, monitoring or other
                            solutions designed to assist in identifying or
                            tracking activities that Homie &amp; Co considers to
                            be illegal or violations of these Terms, to the
                            extent such measures are allowed by applicable law.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Third Party Materials.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;The Service may contain links to third party
                            web sites ("Linked Services"). The Linked Services
                            are not under Homie &amp; Co’s control and Homie
                            &amp; Co is not responsible for any Linked Service.
                            Homie &amp; Co provides these links as a convenience
                            and the inclusion of any link does not imply Homie
                            &amp; Co’s endorsement or an association with its
                            operators. When you access a Linked Service, you do
                            so at your own risk. Also, in the course of using a
                            Service offered through the Service, you may
                            download to your device content that is provided by
                            third parties or access services and Linked Services
                            provided by third parties, such as social media
                            partners, wireless carriers, and third party
                            software application developers. If you obtain
                            anything from a third party over the Internet,
                            directly or indirectly through the Service or a
                            Service, including but not limited to recipes,
                            ringtones, music, applications, stock quotes,
                            newscasts, weather reports, any content or other
                            materials and information for use on or with your
                            device and the Service ("Third Party Materials"),
                            you understand that you receive such Third Party
                            Materials from the third party supplier and not from
                            Homie &amp; Co. Your relationship with respect to
                            Third Party Materials is with the supplier, and not
                            with Homie &amp; Co. It is your responsibility to
                            review the privacy policies and terms of use that
                            apply to third party services, Linked Services and
                            Third Party Materials. Any claims you might have
                            with respect to third party services, Linked
                            Services and Third Party Materials are against the
                            supplier and not against Homie &amp; Co.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            User Content.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;The Service may contain communication features
                            (such as blogs, support forums, etc.) designed to
                            enable users to communicate with Homie &amp; Co and
                            other members, and Content may also be posted to the
                            Service or particular Service by users. "User
                            Content" means all information, graphics, text,
                            images, software, sound files, video, communications
                            and other materials, including all metadata
                            associated with any content, that is posted to the
                            Service or a Service by users of the Service (except
                            Feedback, defined in&nbsp;
                        </span>
                        <a href="#section8">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                Section 8
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            . All User Content is considered to be
                            non-confidential and non-proprietary. You are solely
                            responsible for all User Content uploaded,
                            downloaded, posted, emailed, transmitted, stored or
                            otherwise made available by you or through your
                            account. Homie &amp; Co may not control the User
                            Content posted or transmitted using the Service and
                            it does not guarantee the accuracy, integrity or
                            quality of any User Content. Although Homie &amp; Co
                            does not pre-screen the User Content, Homie &amp; Co
                            reserves the right to determine whether any User
                            Content is appropriate and in compliance with the
                            applicable Terms, and may pre-screen, filter,
                            restrict, block, move, refuse, modify or remove User
                            Content at any time in its sole discretion, without
                            prior notice. Homie &amp; Co will not be liable for
                            any User Content. Homie &amp; Co does not guarantee
                            the security or availability of any User Content
                            transmitted or stored using the Service or a
                            Service, and makes no representations that it will
                            monitor access or use of the Service or a Service.
                            Homie &amp; Co encourages you not to post or share
                            sensitive personal information.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            License to User Content.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Homie &amp; Co does not claim ownership of
                            User Content you submit or make available using the
                            Service or an associated Service. However, by
                            submitting, posting or transmitting User Content
                            and/or personally identifiable information on areas
                            of the Service that are accessible by other users,
                            you grant to Homie &amp; Co and those members who
                            have access to that User Content a worldwide,
                            royalty-free, non-exclusive license to use,
                            transmit, distribute, reproduce, modify, adapt,
                            disclose to third parties, publish, translate,
                            publicly perform and publicly display that User
                            Content, and to publish your name and other
                            personally identifiable information in connection
                            with that User Content. Homie &amp; Co may disclose
                            your identity (if it knows it) to any third party
                            that claims any material posted or uploaded by you
                            to the Service is a violation of their intellectual
                            property rights, or of their right to privacy. By
                            submitting, posting or transmitting User Content on
                            areas of the Service that are accessible to other
                            users, you represent and warrant that you are the
                            owner of that User Content or have authorization to
                            distribute it (including all consents required under
                            applicable privacy laws) and that the use and
                            publication of the User Content does not violate any
                            law. You understand that in order to provide the
                            Service and any associated Service and make your
                            User Content available on it, Homie &amp; Co may
                            transmit your User Content across various public
                            networks, in various media, and may reproduce,
                            modify or change your User Content, Homie &amp; Co
                            may store and retrieve the User Content, may make it
                            available to users and other members, and may amend
                            it for compliance with technical requirements, and
                            otherwise in connection with operation of the
                            Service. Homie &amp; Co is not responsible, or
                            liable to any third party, for the content or
                            accuracy of any materials posted by you or any other
                            user of the Service. Homie &amp; Co has the right to
                            remove any material or posting made on the Service,
                            for any reason, including non-compliance with
                            the&nbsp;
                        </span>
                        <a href="#conduct">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                Code of Conduct
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            .
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Promoted Service.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Homie &amp; Co may propose to non-Premium
                            Users a selection of Third-party Services and offers
                            via the Cards Carrousel present at the top of the
                            Family Dashboard or any other section of the
                            Service. This selection will depend on several user
                            parameters including but not limited to Country, and
                            Language used. In regard to our&nbsp;
                        </span>
                        <Link to={AppRoutes.privacyPolicy}>
                            <a>
                                <span
                                    style={{
                                        color: "#4784ec",
                                        fontSize: "10.5pt",
                                    }}
                                >
                                    Privacy Statement
                                </span>
                            </a>
                        </Link>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            , Homie &amp; Co will not share or disclose any
                            Personal User information to the Promoted Services
                            Providers. Feel free to review our Privacy policy in
                            more detail&nbsp;
                        </span>
                        <Link to={AppRoutes.privacyPolicy}>
                            <a>
                                <span
                                    style={{
                                        color: "#4784ec",
                                        fontSize: "10.5pt",
                                    }}
                                >
                                    Privacy Statement
                                </span>
                            </a>
                        </Link>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            .
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Feedback.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;You agree that, if you submit or provide
                            suggestions for improving Homie &amp; Co products
                            and services ("Feedback") to Homie &amp; Co, by the
                            act of submission or suggestion you effectively
                            irrevocably assign to Homie &amp; Co all right,
                            title and interest throughout the world in the
                            Feedback without the right to any compensation or
                            royalties from Homie &amp; Co and, to the extent
                            allowed by applicable law, you waive any moral
                            rights you may have in the Feedback. Further, you
                            effectively irrevocably waive and agree never to
                            assert any claims against Homie &amp; Co or any of
                            Homie &amp; Co’s successors in interest with respect
                            to Feedback you submit or provide to Homie &amp; Co.
                            You also agree that this assignment and waiver will
                            be effective without the need for any further action
                            or execution of any further document by you.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Homie &amp; Co Content; Proprietary Rights; License;
                            Updates.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;"Homie &amp; Co Content" means all
                            information, graphics, text, images, software, sound
                            files, video, communications and other materials
                            (including all metadata associated with any content)
                            that Homie &amp; Co and its licensors provide to you
                            in connection with the Service. Homie &amp; Co and
                            its licensors own all Homie &amp; Co Content,
                            including the selection and arrangement of the Homie
                            &amp; Co Content and the "look and feel" of the
                            Service. Homie &amp; Co and its licensors own all
                            legal rights, title and interest in and to the
                            Service, Homie &amp; Co Content, and any software
                            (including updates and upgrades thereto) provided to
                            you as part of or in connection with your use of the
                            Service ("Software"), including any and all
                            intellectual property and other proprietary rights
                            that exist therein. Homie &amp; Co grants you a
                            personal, non-exclusive, nontransferable, limited
                            license to use the Software as provided to you by
                            Homie &amp; Co as part of the Service and in
                            accordance with the Terms. Homie &amp; Co may from
                            time to time send you updates to the Software which
                            may be automatically downloaded and installed to
                            your device. These updates may include bug fixes,
                            feature enhancements, improvements, or new versions
                            of the Software. You hereby agree that Homie &amp;
                            Co may automatically deliver these updates to you
                            and you will receive and install them on your device
                            as required.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            No Warranties.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;The Service, Linked Services, Content, Third
                            Party Materials, and Services, including without
                            limitation, updates and upgrades for the software
                            installed on your device that you download to your
                            device via the Service, are provided "AS IS," "WITH
                            ALL FAULTS" and "AS AVAILABLE," and the entire risk
                            as to satisfactory quality, performance,
                            availability, security, accuracy and effort is with
                            you to the maximum extent permitted by applicable
                            law. Homie &amp; Co and its suppliers make no
                            representations, warranties or conditions, express,
                            implied or statutory, including without limitation,
                            the implied warranties of merchantability,
                            merchantable or satisfactory quality, fitness for a
                            particular purpose, title or non-infringement. In
                            particular, Homie &amp; Co, its suppliers and
                            licensors make no warranty that (i) the Service or
                            Services will meet your requirements; (ii) the
                            Service or Services will be provided on an
                            uninterrupted, timely, secure or error-free basis;
                            (iii) any information obtained by you using the
                            Service or Services will be accurate or reliable; or
                            (iv) any defects or errors in the Software provided
                            to you via the Service will be corrected. Homie
                            &amp; Co will not be liable for any loss or damage
                            caused by a distributed denial-of-service attack,
                            viruses or other technically harmful material that
                            may infect your computer, device, computer programs,
                            data or other proprietary material due to your use
                            of the Service or as a result of downloading any
                            material available on it, or on any website linked
                            to it. All content and other material you download
                            or obtain through the Service is accessed at your
                            own risk, and you will be solely responsible for any
                            damage to your device or loss of data or content
                            that results from download of any material. Homie
                            &amp; Co does not represent or warrant that the
                            Service or any feature or part thereof is available
                            or appropriate for use in any particular location.
                            You are responsible for compliance with all
                            applicable laws in connection with your access to
                            and use of the Service. You may have additional
                            consumer rights under your local laws that these
                            Terms cannot change. In particular, to the extent
                            local legislation implies statutory terms which
                            cannot be excluded, those terms are deemed
                            incorporated into this document but Homie &amp; Co's
                            liability for a breach of those statutory implied
                            terms is limited in accordance with and to the
                            extent permissible under the applicable legislation.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Disclaimer of Certain Damages.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;To the maximum extent permitted by law, in no
                            event will Homie &amp; Co or any supplier be liable
                            for (a) any consequential, special, incidental,
                            indirect or punitive damages; or (b) damages for
                            loss of profits or confidential or other
                            information, for business interruption, for personal
                            injury, property damage, for loss of privacy, for
                            failure to meet any duty of good faith or reasonable
                            care or for negligence, in (a) and (b) arising out
                            of, based on, resulting from or in any way related
                            to these Terms, the Service, your use of the
                            Service, Linked Services, Homie &amp; Co Content,
                            Third Party Materials or Services, even if Homie
                            &amp; Co or any supplier has been advised of the
                            possibility of such damages. Some jurisdictions do
                            not allow the exclusion or limitation of incidental
                            or consequential damages, or of damages for personal
                            injury, so the above limitations or exclusions of
                            damages may not apply to you.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Limitation of Liability and Exclusive Remedy.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;To the maximum extent permitted by applicable
                            law, Homie &amp; Co’s and its suppliers’ maximum,
                            aggregate liability to you, and your exclusive
                            remedy under these Terms for any and all damages,
                            injury, property damage and losses arising from any
                            and all claims and causes of action arising out of,
                            based on, resulting from or in any way related to
                            these Terms, the Service, Linked Services, Homie
                            &amp; Co Content, Third Party Materials, Services or
                            your use of the Service will be to recover the
                            actual damages you incur based upon reasonable
                            reliance on the Service up to five Euros (5.00€).
                            The existence of multiple claims or suits under or
                            related to these Terms, the Service, Linked
                            Services, Homie &amp; Co Content, Third Party
                            Materials or Service will not enlarge or extend the
                            limitation of money damages. To the maximum extent
                            permitted by applicable law, these actual money
                            damages will be your sole remedy. Some jurisdictions
                            do not allow certain contract terms. If a court of
                            competent jurisdiction deems the above liability cap
                            unenforceable, it will, to that extent, not be
                            applicable but instead the words "up to five Euros
                            (5.00€)" will be deemed replaced by the words "up to
                            the value of the transaction in respect of which
                            liability has arisen." Nothing in this Section 12
                            will be interpreted as purporting to exclude
                            liability which cannot under prevailing legislation
                            be excluded, but any such statutory entitlement
                            available to you will be deemed limited to the
                            extent (if at all) permissible under such
                            legislation.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Indemnity.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;You will defend, indemnify and hold Homie
                            &amp; Co, its directors, officers, employees,
                            agents, partners, suppliers and licensors harmless
                            from any third party claim or demand, including
                            reasonable attorneys’ fees, relating to or arising
                            from (a) any User Content submitted, posted,
                            transmitted or otherwise made available under your
                            account using the Service or a Service; (b) your use
                            of the Service or Service and activities occurring
                            using your account information; (c) any violation by
                            you of the Terms; and (d) your violation of any
                            third party rights. This indemnification will
                            survive the termination or expiration of the Terms
                            and your use of the Service or Service.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Independent Remedies.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;The exclusion of damages under&nbsp;
                        </span>
                        <a href="#section11">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                Section 11
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;is independent of your exclusive remedy
                            in&nbsp;
                        </span>
                        <a href="#section12">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                Section 12
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;and it survives even if the exclusive remedy
                            fails of its essential purpose or otherwise is
                            deemed unenforceable. Each of these limitations of
                            liability in Sections 11 and 12 apply without regard
                            to whether damages arise from (a) breach of
                            contract, (b) breach of warranty, (c) fault or tort,
                            including negligence and misrepresentation, (d)
                            strict liability or (e) any other cause of action,
                            to the extent the exclusions and limitations are not
                            prohibited by applicable law.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Copyright Notice and Takedown Procedures.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;You agree to respect the intellectual property
                            rights of Homie &amp; Co and third parties. If you
                            believe that material available through the Service
                            infringes the copyright of any third party, please
                            notify Homie &amp; Co’s copyright agent by emailing
                            at&nbsp;
                        </span>
                        <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                            hello@thehomie.app
                        </span>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;or by mail at Homie &amp; Co – 16, rue
                            Théodore de Banville – 75017 – Paris - France. After
                            receiving notice, Homie &amp; Co may remove or
                            disable access to any potentially infringing
                            material. Homie &amp; Co has the right to terminate
                            any account or right of access to the Service for
                            infringement by the account holder, or person
                            accessing the Service, of Homie &amp; Co’s or
                            others’ rights.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Applicable Law and Forum.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Subject to mandatory legal provisions, the use
                            of Homie &amp; Co services shall be exclusively
                            governed by French Laws (France) being the exclusive
                            place of jurisdiction. You and Homie &amp; Co agree
                            to submit to exclusive jurisdiction in Paris Court
                            in France for any and all disputes, claims and
                            actions arising from or in connection with the
                            Service or the Terms. The United Nations Convention
                            on the International Sale of Goods is expressly
                            excluded from application to the Terms. You will not
                            use or export anything from the Service or a Service
                            (including Software) in violation of U.S. or any
                            other country’s export laws and regulations or the
                            Terms. Unless the following limitation is prohibited
                            by applicable law, any claim or cause of action
                            arising out of or related to the Terms or the use of
                            the Service or a Service must be filed within one
                            (1) year after the cause of action arose or be
                            barred.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Changes to these Terms.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Homie &amp; Co reserves the right to change
                            these Terms at any time. The updated version will be
                            effective upon the date of posting unless indicated
                            otherwise in the updated Terms. You can review the
                            most current version of the Terms at any time
                            at&nbsp;
                        </span>
                        <a href="https://thehomie.app">
                            <u>
                                <span
                                    style={{
                                        color: "#1155cc",
                                        fontSize: "10.5pt",
                                    }}
                                >
                                    www.thehomie.app
                                </span>
                            </u>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            . Your acceptance of the Terms by clicking a box (if
                            one is made available to you), or your continued use
                            of the Service after the updated version of the
                            Terms becomes effective constitutes your binding
                            acceptance of the updated version of the Terms. If
                            you do not agree to any updated version, you must
                            immediately terminate your registration and/or
                            discontinue your use of the Service.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Termination.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;You may terminate your account registration or
                            stop using the Service at any time. Homie &amp; Co
                            reserves the right to terminate these Terms and your
                            access to the Service or any portion thereof,
                            including any Services at any time, without notice.
                            Sections&nbsp;
                        </span>
                        <a href="#section2">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                2
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section5">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                5
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section7">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                7
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section8">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                8
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section9">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                9
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section10">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                10
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section11">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                11
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section12">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                12
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section13">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                13
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section14">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                14
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section16">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                16
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section18">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                18
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            ,&nbsp;
                        </span>
                        <a href="#section20">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                20
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            , and&nbsp;
                        </span>
                        <a href="#section22">
                            <span
                                style={{ color: "#4784ec", fontSize: "10.5pt" }}
                            >
                                22
                            </span>
                        </a>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;will survive termination.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Notices.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Homie &amp; Co may give you all notices
                            (including legal process) that Homie &amp; Co is
                            required to give by any lawful method, including by
                            posting notice on the Service or by sending it to
                            any email or mailing address that you provide to
                            Homie &amp; Co. You agree to keep your email and
                            mailing addresses current and to check for notices
                            posted on the Service. You agree to send Homie &amp;
                            Co notice by mailing it to Homie &amp; Co’s "Address
                            for Legal Notices" which is:
                        </span>
                        <br />
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Homie &amp; Co
                        </strong>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            <br />
                        </strong>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            8/41, Ashok Nagar
                        </strong>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            <br />
                        </strong>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            202001, Aligarh, UP, India
                        </strong>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            General.
                        </strong>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            <br />
                        </strong>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            A.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            If a court of competent jurisdiction determines that
                            any part of these Terms is invalid or unenforceable,
                            then it will replace the invalid or unenforceable
                            provision with a valid, enforceable provision that
                            most closely matches the intent of the original
                            provision and the remainder of these Terms will
                            continue in effect. The section titles in these
                            Terms are used only for the parties’ convenience and
                            have no legal or contractual significance. Homie
                            &amp; Co may assign these Terms, in whole or in
                            part, at any time with or without notice to you. You
                            may not assign these Terms or assign, transfer, or
                            sublicense your rights, if any, in the Service.
                            Homie &amp; Co’s failure to act with respect to a
                            breach by you or others does not waive its right to
                            act with respect to subsequent or similar breaches.
                            These Terms (including the Privacy Statement, Code
                            of Conduct and any other incorporated terms)
                            constitute the entire agreement between you and
                            Homie &amp; Co with respect to the Service. These
                            Terms supersede all prior or contemporaneous
                            communications of any kind between you and Homie
                            &amp; Co with respect to the Service.
                        </span>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            B.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Homie &amp; Co may provide notifications,
                            whether such notifications are required by law or
                            are for marketing or other business-related
                            purposes, to you via email notice, written or hard
                            copy notice, or through posting of such notice on
                            our website or mobile application, as determined by
                            Homie &amp; Co in our sole discretion. Homie &amp;
                            Co reserves the right to determine the form and
                            means of providing notifications to our Users,
                            provided that you may opt out of certain means of
                            notification as described in this Agreement. Homie
                            &amp; Co is not responsible for any automatic
                            filtering you or your network provider may apply to
                            email notifications we send to the email address you
                            provide us. Homie &amp; Co may, in our sole
                            discretion, modify or update this Agreement from
                            time to time, and so you should review this page
                            periodically. When we change the Agreement in a
                            material manner, we will update the ‘last modified’
                            date at the bottom of this page. Your continued use
                            of the Service after any such change constitutes
                            your acceptance of the new Terms of Use. If you do
                            not agree to any of these terms or any future Terms
                            of Use, do not use or access (or continue to access)
                            the Service.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Support.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Homie &amp; Co does not offer customer support
                            for the Service or any feature or service available
                            through the Service, unless Homie &amp; Co has
                            posted information on the Service that we offer
                            customer support for a particular section of the
                            Service.
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "decimal",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <strong
                            style={{ color: "#494949", fontSize: "10.5pt" }}
                        >
                            Copyright and Trademark Notices.
                        </strong>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            &nbsp;Except for User Content, all content offered
                            in connection with the Service is © 2021 Homie &amp;
                            Co SAS and/or its suppliers, 16, rue Théodore de
                            Banville, 75017, Paris, France All rights reserved.
                            Copyright and other intellectual property laws and
                            treaties protect any software and content provided
                            on the Service. Homie &amp; Co and/or its suppliers
                            own the title, copyright and other intellectual
                            property rights in the Service, Homie &amp; Co
                            Content, Feedback and any Software (including any
                            updates and upgrades that you download for your
                            Homie &amp; Co device via the Service). The Homie
                            &amp; Co logo, the Homie &amp; Co quietly brilliant
                            logo and/or other Homie &amp; Co product names and
                            services referenced on the Service are the
                            trademarks of Homie &amp; Co Corporation. All other
                            company names, product names, service names and
                            logos referenced on the Service may be the
                            trademarks of their respective owners.
                        </span>
                    </p>
                </li>
            </ol>
            <h1 id="conduct">
                <span style={{ color: "#272727", fontSize: "25.5pt" }}>
                    Code of conduct
                </span>
            </h1>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    This Code of Conduct applies to the HomieApp Service. It
                    also applies to any portion of the Service that displays a
                    link to this Code of Conduct. This Code of Conduct refers to
                    communication features as the “services.” Please read the
                    Code of Conduct and comply with it in connection with the
                    services you may use.
                </span>
            </p>
            <br />
            <h3>
                <span style={{ color: "#272727", fontSize: "15pt" }}>
                    Member Qualifications
                </span>
            </h3>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    As a user of a service you will uphold this Code of Conduct,
                    and are responsible for all activities and content you
                    post/upload. In addition to upholding this Code of Conduct,
                    you are responsible for adhering to all applicable local and
                    national laws.
                </span>
            </p>
            <br />
            <h3>
                <span style={{ color: "#272727", fontSize: "15pt" }}>
                    Prohibited Uses
                </span>
            </h3>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    You will not upload, post, transmit, transfer, distribute or
                    facilitate distribution of any content (including text,
                    images, sound, video, data, information or software) or
                    otherwise use the service in a way that:
                </span>
            </p>
            <ul>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            depicts nudity of any sort including full or partial
                            human nudity or nudity in non-human forms such as
                            cartoons, fantasy art or manga;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            incites, advocates, or expresses pornography,
                            obscenity, vulgarity, profanity, hatred, bigotry,
                            racism or gratuitous violence;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            misrepresents the source of anything you post or
                            upload, including impersonation of another
                            individual or entity or sends any altered, deceptive
                            or false source-identifying information;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            provides or creates links to external sites that
                            violate this Code of Conduct;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            includes content that is protected by intellectual
                            property laws, rights of privacy or publicity, or
                            any other applicable law unless you own or control
                            such rights or have obtained necessary consents and
                            releases;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            is intended to harm or exploit minors in any way;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            is designed to solicit, or collect personally
                            identifiable information of any minor (anyone under
                            18 years old), including, but not limited to: name,
                            email address, home address, phone number or the
                            name of their school;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            deletes, tampers with or revises any information,
                            material or content posted by any other person or
                            entity;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            invades anyone's privacy by attempting to harvest,
                            collect, store or publish private or personally
                            identifiable information, such as passwords, account
                            information, credit card numbers, addresses or other
                            contact information without their knowledge and
                            willing consent;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            is illegal or violates applicable local and national
                            laws, including but not limited to child
                            pornography, bestiality, incest, illegal drugs,
                            software piracy and harassment;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            threatens, stalks, defames, defrauds, degrades,
                            victimizes or intimidates an individual or group of
                            individuals for any reason; including without
                            limitation, on the basis of age, gender, disability,
                            ethnicity, sexual orientation, race or religion, or
                            incites or encourages anyone else to do so;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            harms or disrupts, or intends to harm or disrupt,
                            another user's computer or would allow you or others
                            to illegally access software or bypass security on
                            Web sites or servers, including but not limited to,
                            spamming or attempts to probe, scan or test the
                            vulnerability of a system or network or to breach
                            security or authentication measures;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            takes any action which could impose a significant
                            burden (as determined by Homie &amp; Co) on the
                            infrastructure of any Homie &amp; Co services, such
                            as excessively high volume data transfers or
                            bandwidth consumption, excessively high storage,
                            hosting of a web server, internet relay chat server,
                            or any other server, and non-traditional end user
                            activities;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            sends or attempts to send unsolicited messages,
                            including promotions or advertisements for products
                            or services, "spam," "chain mail" or "junk mail";
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            accesses, tampers with or uses non-public areas of
                            any service, computer system or any Homie &amp; Co
                            website, or causes damage to Homie &amp; Co’s
                            business, reputation, employees, facilities, or to
                            any person;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            attempts to access, search, or extract data from any
                            service or any Homie &amp; Co website with any
                            engine, software, tool, agent, robot, spider,
                            scraper, device or mechanism other than the software
                            or search agents provided by Homie &amp; Co or other
                            generally available third party web browsers;
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            "frames" any Homie &amp; Co service or any Homie
                            &amp; Co website or otherwise makes it look as if
                            you have a relationship with Homie &amp; Co or that
                            Homie &amp; Co has endorsed you for any purpose; or
                        </span>
                    </p>
                </li>
                <li
                    style={{
                        listStyleType: "disc",
                        color: "#494949",
                        fontSize: "10.5pt",
                    }}
                >
                    <p>
                        <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                            attempts to impersonate a Homie &amp; Co employee,
                            agent, manager, host, administrator, moderator,
                            another user or any other person through any means.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    You will not use any form of automated device or computer
                    program that enables the submission of postings without the
                    express written consent of Homie &amp; Co.
                </span>
            </p>
            <br />
            <h3>
                <span style={{ color: "#272727", fontSize: "15pt" }}>
                    Termination and Cancellation
                </span>
            </h3>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    Homie &amp; Co reserves the right, at its sole discretion,
                    to review and remove user content without notice, and to
                    delete content and user accounts. Homie &amp; Co reserves
                    the right, at its sole discretion, to ban participants or
                    terminate access to services.
                </span>
            </p>
            <br />
            <h3>
                <span style={{ color: "#272727", fontSize: "15pt" }}>
                    Rights and Responsibilities
                </span>
            </h3>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    We encourage you not to share information that others could
                    use to harm you. We encourage parents to be aware of and
                    help exercise control over content posted by and activities
                    of their minors to keep them safe online. Homie &amp; Co is
                    not responsible for the content of any user-created posting,
                    listing or message. The decision to view content or engage
                    with others is yours. We advise you to use your judgment.
                </span>
            </p>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    You are responsible for protecting your computer against
                    interference, spyware or viruses that may be encountered for
                    downloaded items from the service. We recommend you install
                    a virus protection program on your computer and keep it up
                    to date.
                </span>
            </p>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    Homie &amp; Co reserves the right to amend or change the
                    Code of Conduct or any service at any time without notice.
                    We encourage you to review these guidelines periodically to
                    ensure you are in compliance.
                </span>
            </p>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    Some information you provide or upload to the service may be
                    stored outside of the country in which you reside.
                </span>
            </p>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    You can report abuses of this Code of Conduct to&nbsp;
                </span>
                <span style={{ color: "#4784ec", fontSize: "10.5pt" }}>
                    hello@thehomie.app
                </span>
            </p>
            <p>
                <span style={{ color: "#494949", fontSize: "10.5pt" }}>
                    Effective Date : March, 27 2024
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
        </div>
    );
}
