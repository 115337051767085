import { AxiosInstance } from "axios";
import { ErrorHandlerService } from "../../logic/internal/error-handler.service";
import { LoggingService } from "../../logic/internal/logging.service";
import AxiosService from "../axios-client";
import { ApiRoutes } from "../../constants/api-urls";
import { DemographicMetadata } from "../../models/analytics/demographic-metadata";

export default class MetadataApi {
    private axiosService: AxiosService = AxiosService.getInstance();
    private axiosClient?: AxiosInstance = this.axiosService.getAxiosClient;

    constructor() {}

    private static _instance: MetadataApi;
    public static getInstance(): MetadataApi {
        return this._instance || (this._instance = new this());
    }

    public async fetchDemographicMetadata(): Promise<DemographicMetadata | null> {
        try {
            const response = await this.axiosClient?.get(
                ApiRoutes.DEMOGRAPHIC_METADATA_URL
            );
            if (response) {
                const { org: internet_provider, loc: location } =
                    response?.data;
                delete response.data["org"];
                delete response.data["readme"];
                delete response.data["loc"];
                const demographicMetadata: DemographicMetadata = {
                    ...(response.data as DemographicMetadata),
                };
                demographicMetadata.internet_provider = internet_provider;
                demographicMetadata.location = location;
                return demographicMetadata;
            }
        } catch (error) {
            console.error("Error fetching country and timezone:", error);
        }
        return null;
    }
}
