import { AppConstants } from "../../constants/app-constants";
import { ConsoleColors } from "../../constants/console-colors";

export class LoggingService {
    private environment = process.env.REACT_APP_ENV;
    // generally used to log errors
    public logErrors(message: string, err: any) {
        if (this.environment == AppConstants.prodEnv) return;
        console.error(
            ConsoleColors.FgRed + "%s" + ConsoleColors.Reset,
            `Error Log: [${new Date()}] - ${message}`,
            err
        );
    }

    // generally used to log when something needs attention
    public logActions(action: string, context?: any) {
        if (this.environment == AppConstants.prodEnv) return;
        console.info(
            ConsoleColors.FgYellow + "%s" + ConsoleColors.Reset,
            `Action Log: [${new Date()}] - ${action}`,
            context ?? ""
        );
    }

    // generally used to log some valuable info required to track task completions
    public logInfo(info: string, context?: any) {
        if (this.environment == AppConstants.prodEnv) return;
        console.info(
            ConsoleColors.FgCyan + "%s" + ConsoleColors.Reset,
            `Info Log: [${new Date()}] - ${info}`,
            context ?? ""
        );
    }

    // generally used while development process to log things
    public debugLog(debug: string, context?: any) {
        if (this.environment == AppConstants.prodEnv) return;
        console.debug(
            ConsoleColors.BgGray + "%s" + ConsoleColors.Reset,
            `Debug Log: [${new Date()}] - ${debug}`,
            context ?? ""
        );
    }
}
