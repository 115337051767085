import { AxiosError } from "axios";
import { LoggingService } from "./logging.service";

export class ErrorHandlerService {
    constructor() {}

    /**
     * Handle all types of errors
     *
     * @param {string} customErrorCode - custom err code
     * @param {any} err - error object
     * @throws {CustomErrorException} - handled custom error exception
     */
    public handleError(err: any): void {
        if (err instanceof AxiosError) {
            this.handleAxiosError(err);
        } else if (err instanceof Error && err.name === "ReferenceError") {
            this.handleRefrenceError(err);
        }

        new LoggingService().logActions("unknown error", err);
        // const errorLog: ErrorLog = {
        //     code: err.code,
        //     customErrorMsg: errorCodeMap[customErrorCode],
        //     msg: err.msg,
        //     stack: err.stack,
        // };
        // this.loggingService.logErrors("unknown error", errorLog);
        // throw new CustomErrorException(customErrorCode);
    }

    // Handle Axios-specific errors
    private handleAxiosError(err: any) {
        new LoggingService().logActions(`axios error`, err);

        // const errorLog: ErrorLog = {
        //     code: err.code,
        //     msg: err.message,
        //     customErrorMsg: errorCodeMap[customErrorCode],
        //     stack: err.stack,
        // };
        // this.loggingService.logErrors("axios error", errorLog);

        // throws custom exception
        // throw new CustomErrorException(customErrorCode);
    }

    // Handle JavaScript reference errors
    private handleRefrenceError(err: any) {
        new LoggingService().logActions("refrence error", err);
        // const errorLog: ErrorLog = {
        //     code: customErrorCode,
        //     msg: err.message,
        //     customErrorMsg: errorCodeMap[customErrorCode],
        //     stack: err.stack,
        // };
        // this.loggingService.logErrors("refrence error", errorLog);
        // throw new CustomErrorException(customErrorCode);
    }
}
