import { AxiosInstance, AxiosRequestConfig } from "axios";
import AxiosService from "../axios-client";
import { ErrorHandlerService } from "../../logic/internal/error-handler.service";
import { LoggingService } from "../../logic/internal/logging.service";
import { ApiRoutes } from "../../constants/api-urls";
import { AuthResponse } from "../../models/response/auth/auth-response.dto";
import { GoogleAuthRequest } from "../../models/request/auth/google-auth-req.dto";
import { AppUser } from "../../models/user/user.dto";

export default class UserApi {
    private axiosService: AxiosService = AxiosService.getInstance();
    private axiosClient?: AxiosInstance = this.axiosService.getAxiosClient;
    private loggingService: LoggingService = new LoggingService();
    private errorHandlerService: ErrorHandlerService =
        new ErrorHandlerService();

    constructor() {}

    private static _instance: UserApi;
    public static getInstance(): UserApi {
        return this._instance || (this._instance = new this());
    }

    /// Fetch user data
    ///
    /// @param {GoogleAuthRequest} googleAuthRequest - auth request data
    /// @returns {AuthResponse} - fetch tokens & user info
    public async fetchUser(): Promise<AppUser | null> {
        var url = ApiRoutes.GET_USER_DATA_URL;

        try {
            const response = await this.axiosClient?.get(url);
            if (!response) {
                return null;
            }

            const appUser: AppUser = response["data"]["data"];
            return appUser;
        } catch (error: any) {
            this.errorHandlerService.handleError(error);
        }
        return null;
    }
}
