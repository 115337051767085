import Spacer from "../../../../../components/spacer";
import "./feature-card.css";

type FeatureCardProps = {
    title: string;
    content: string;
    icon: any;
    isSelected: boolean;
};
const FeatureCard = ({
    title,
    content,
    icon,
    isSelected,
}: FeatureCardProps) => {
    return (
        <div className="feature-card-wrapper">
            <Spacer height="2rem" />
            <img height="80px" width="80px" src={icon} alt={title + "-icon"} />
            <Spacer height="3rem" />
            <span className="feature-card-title">{title}</span>
            <Spacer height="13px" />
            <p className="feature-card-content">{content}</p>
        </div>
    );
};

export default FeatureCard;
