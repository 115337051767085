import React from "react";
import "./copy-link.css";
import { useDispatch } from "react-redux";
import { updateAppSnackbar } from "../../../redux/reducers/app.reducer";
import AssetProvider from "../../../constants/app-assets";
import { AppStrings } from "../../../constants/string-constants";

const CopyLink = () => {
    const dispatch = useDispatch();
    const referral_url = "https://thehomie.app";

    const copyToClipboard = () => {
        console.log("CLICKING");
        navigator.clipboard.writeText(referral_url);
        dispatch(
            updateAppSnackbar({
                showSnackbar: true,
                snackbarText: AppStrings.LINK_COPIED,
            })
        );
    };

    return (
        <div onClick={() => copyToClipboard()} className="copy-link-wrapper">
            <p className="copy-text">{referral_url}</p>
            <div onClick={copyToClipboard} id="copy-btn">
                <img
                    height={"24px"}
                    width={"24px"}
                    src={AssetProvider.copyLinkIcon}
                    alt="copy-btn"
                />
            </div>
        </div>
    );
};

export default CopyLink;
