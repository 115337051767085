import { createSlice } from "@reduxjs/toolkit";
import UserActions from "../actions/user.action";
import { UserState } from "../types/user-state.type";
import { AppConstants } from "../../constants/app-constants";

export const initialUserState: UserState = {
    isSignedIn: false,
};
const userActions: UserActions = new UserActions();

export const userSlice = createSlice({
    name: AppConstants.user,
    initialState: initialUserState,
    reducers: {
        addUser: (state, action) => userActions.addUser(state, action),
        removeUser: (state, action) => userActions.removeUser(state, action),
    },
});

export const { addUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
