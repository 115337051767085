import React, { useState, useEffect, useRef } from "react";

type FillingBarProps = {
    handleBarFill: Function;
    pause: boolean;
    animationTime: number;
};

const FillingBar = ({
    handleBarFill,
    pause,
    animationTime,
}: FillingBarProps) => {
    const [fillHeight, setFillHeight] = useState<number>(0); // in % (max 100)
    const barFillTime: number = animationTime * 1000; // 10s
    const [intervalId, setIntervalId] = useState<any>(null);

    useEffect(() => {
        if (pause) {
            clearTimer();
        } else {
            setupTimer();
        }
    }, [pause]);

    useEffect(() => {
        return () => clearTimer();
    }, []);

    function setupTimer() {
        clearTimer();
        const currIntervalId = setInterval(() => {
            setFillHeight((prevFillHeight: number) => {
                // Calculate the new value based on the previous state
                const newFillHeight = prevFillHeight + 1;
                // Ensure the new value does not exceed 100
                return newFillHeight > 100 ? 100 : newFillHeight;
            });
        }, barFillTime / 100); // Adjust fill duration by modifying interval time (ms)

        setIntervalId(currIntervalId);
    }

    function clearTimer() {
        if (intervalId == null) return;
        clearInterval(intervalId);
        setIntervalId(null);
    }

    useEffect(() => {
        if (fillHeight >= 100) {
            handleBarFill();
        }
    }, [fillHeight]);

    const barStyle: React.CSSProperties = {
        height: `${fillHeight}%`,
    };

    return <div style={barStyle} className="filling-bar" />;
};

export default FillingBar;
