import Spacer from "../../../../components/spacer";
import AssetProvider from "../../../../constants/app-assets";
import { isMobile } from "../../../../utils/helper-functions";
import FeatureCard from "./components/feature-card";
import "./feel-secure-section.css";

export default function FeelSecureSection() {
    return (
        <div id="feel-secure-section">
            <div id="feel-secure-content-wrapper">
                <span id="feel-secure-title">Feel Secure with Homie!</span>
                <Spacer height="24px" />
                <p id="feel-secure-para">
                    At Homie, we prioritize your family's privacy and security.
                    <br />
                    <span id="feel-secure-highlight">
                        Here's why you can trust Homie:
                    </span>
                </p>
                <Spacer height="36px" />
                <div className="feature-cards-row">
                    <FeatureCard
                        title="Transparency"
                        content="You'll always know how your data is used and what you’re paying for, fostering trust and peace of mind."
                        isSelected={false}
                        icon={AssetProvider.trust}
                    />
                    {isMobile() && <Spacer height="36px" />}
                    <FeatureCard
                        title="Security"
                        content="Your security is our top priority. Homie utilizes secure encryption technology to safeguard your data."
                        isSelected={true}
                        icon={AssetProvider.secure}
                    />
                    {isMobile() && <Spacer height="36px" />}
                    <FeatureCard
                        title="Reliability"
                        content="Homie is built for ensuring a seamless experience for you and your loved ones. Count on us to keep your family connected."
                        isSelected={false}
                        icon={AssetProvider.reliable}
                    />
                </div>
            </div>
        </div>
    );
}
