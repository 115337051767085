import { createSlice } from "@reduxjs/toolkit";
import { AppConstants } from "../../constants/app-constants";
import AppActions from "../actions/app.action";
import { AppState, UpdateSnackbarPayload } from "../types/app-state.type";
import { AppStrings } from "../../constants/string-constants";

export const initialAppState: AppState = {
    showDialog: false,
    showSnackbar: false,
    snackbarText: AppStrings.DEFAULT_SNACKBAR_TEXT,
};
const appActions: AppActions = new AppActions();

export const appSlice = createSlice({
    name: AppConstants.app,
    initialState: initialAppState,
    reducers: {
        showAppDialog: (state, action) =>
            appActions.showAppDialog(state, action),
        updateAppSnackbar: (
            state,
            action: { payload: UpdateSnackbarPayload; type: string }
        ) => appActions.updateAppSnackbar(state, action),
    },
});

export const { showAppDialog, updateAppSnackbar } = appSlice.actions;
export default appSlice.reducer;
