export default class AppRoutes {
    static home: string = "/";
    static auth: string = "/auth";
    static storyHome: string = "/story";
    static storyView: string = "/story-view";
    static writeStory: string = "/story-write";
    static privacyPolicy: string = "/privacy-policy";
    static termsAndConditions: string = "/terms-and-conditions";
    static waitlist: string = "/waitlist";
}
