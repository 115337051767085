import React, { useState, useEffect, useRef } from "react";
import "./expanded-card.css";
import FeatureIcon from "./feature-icon";
import Spacer from "../../../../../components/spacer";
import FillingBar from "./fill-bar";
import AppAnalyticService from "../../../../../logic/external/analytic.service";
import appFirebaseService from "../../../../../logic/external/app-firebase.service";
import { LocalStoreKeys } from "../../../../../constants/local-storage-keys";

type ExpandedCardProps = {
    icon: any;
    title: string;
    subtitle: string;
    description: string;
    selected: boolean;
    onClick: Function;
    handleBarFill: Function;
    animationTime: number;
};

const ExpandableCard = ({
    icon,
    title,
    subtitle,
    description,
    selected,
    onClick,
    handleBarFill,
    animationTime,
}: ExpandedCardProps) => {
    const [expanded, setExpanded] = useState(selected);
    useEffect(() => {
        setExpanded(selected);
    }, [selected]);
    const [pauseTime, setPauseTimer] = useState<boolean>(false);

    const handleClick = () => {
        AppAnalyticService.firebaseEventLog("feature_tile_click", {
            feature_name: title,
        });
        const uid = localStorage.getItem(LocalStoreKeys.ANALYTICS_USER_ID);
        if (uid) {
            appFirebaseService.addUserInteration(
                uid,
                `${title.replace(" ", "_").toLowerCase()}_feature_tile`
            );
        }
        onClick();
    };

    return (
        <div
            className={`card ${expanded ? "expanded" : ""}`}
            onMouseEnter={() => setPauseTimer(expanded && true)}
            onMouseLeave={() => setPauseTimer(expanded && false)}
            onClick={handleClick}
        >
            {expanded && (
                <FillingBar
                    animationTime={animationTime}
                    pause={pauseTime}
                    handleBarFill={handleBarFill}
                />
            )}
            <div className="feature-col-1">
                {icon && <FeatureIcon icon={icon} isSelected={true} />}
            </div>
            <div className="feature-item-content feature-col-2">
                {title && <h3 className="feature-item-title">{title}</h3>}
                {subtitle && (
                    <h4 className="feature-item-subtitle">{subtitle}</h4>
                )}
                {expanded && <Spacer height="12px" />}
                {expanded && (
                    <p className="feature-item-description">{description}</p>
                )}
            </div>
        </div>
    );
};

export default ExpandableCard;
