import { useState } from "react";
import AssetProvider from "../../../../../constants/app-assets";
import ExpandableCard from "./expanded-card";
import "./join-button.css";
import Lottie from "react-lottie-player";
import { isMobile } from "../../../../../utils/helper-functions";

export const FeatureRow = () => {
    const [featureIndex, setFeatureIndex] = useState<number>(0);

    function updateIndex(selectedIndex: number) {
        setFeatureIndex(selectedIndex);
    }

    const lottieProvider = (featureIndex: number) => {
        let asset: any = AssetProvider.contactLottie;
        switch (featureIndex) {
            case 0: {
                asset = AssetProvider.tasksLottie;
                break;
            }
            case 1: {
                asset = AssetProvider.locationLottie;
                break;
            }
            case 2: {
                asset = AssetProvider.budgetingLottie;
                break;
            }
            case 3: {
                asset = AssetProvider.safeVaultLottie;
                break;
            }
            case 4: {
                asset = AssetProvider.contactLottie;
                break;
            }
            default: {
                asset = AssetProvider.tasksLottie;
            }
        }
        return (
            <Lottie
                animationData={asset}
                play={true}
                goTo={1}
                speed={1}
                style={{
                    // width: `20vw`,
                    // height: `20vw`,
                    // transitionDuration: "1000ms", // 10 secs
                    alignContent: "center",
                }}
                loop={true}
                key={`feature_lottie_${featureIndex}`}
            />
        );
    };

    const getLottieAnimation = (featureIndex: number, isMobile: boolean) => {
        return (
            <div
                style={{
                    position: "relative",
                    height: isMobile ? "auto" : "504px",
                    width: isMobile ? "100%" : "636px",
                    overflow: "hidden",
                }}
            >
                {lottieProvider(featureIndex)}
            </div>
        );
    };

    return (
        <div className="feature-row">
            <div id="features-container">
                <ExpandableCard
                    animationTime={12.5}
                    selected={featureIndex == 0}
                    onClick={() => updateIndex(0)}
                    handleBarFill={() => updateIndex(1)}
                    icon={AssetProvider.task}
                    title="Task Manager"
                    subtitle="Streamline Family Chores and Activities"
                    description="Keep everyone organized with our family task manager. Assign chores, track schedules, and share grocery lists – all in one convenient place. Say goodbye to nagging and hello to harmony with Homie."
                />
                {isMobile() &&
                    featureIndex == 0 &&
                    getLottieAnimation(featureIndex, true)}
                <ExpandableCard
                    animationTime={12.3}
                    selected={featureIndex == 1}
                    onClick={() => updateIndex(1)}
                    handleBarFill={() => updateIndex(2)}
                    icon={AssetProvider.location}
                    title="Homie Locator"
                    subtitle="Stay Connected, Stay Safe"
                    description="Our location sharing feature keeps your family connected and safe. Track your loved ones, coordinate gatherings, and share real-time locations – all at your fingertips."
                />
                {isMobile() &&
                    featureIndex == 1 &&
                    getLottieAnimation(featureIndex, true)}
                <ExpandableCard
                    animationTime={12.5}
                    selected={featureIndex == 2}
                    onClick={() => updateIndex(2)}
                    handleBarFill={() => updateIndex(3)}
                    icon={AssetProvider.bill}
                    title="Bill Manager"
                    subtitle="Stay on Top of Your Finances"
                    description="Manage your family finances effortlessly with our bill management feature. Track due dates, set reminders, and manage payments – all in one place."
                />
                {isMobile() &&
                    featureIndex == 2 &&
                    getLottieAnimation(featureIndex, true)}
                <ExpandableCard
                    selected={featureIndex == 3}
                    animationTime={12}
                    onClick={() => updateIndex(3)}
                    handleBarFill={() => updateIndex(4)}
                    icon={AssetProvider.safeVault}
                    title="Safe Vault"
                    subtitle="Secure Storage for every need"
                    description="Keep all your documents & subscriptions safe and easily accessible with our Family Vault. Experience peace of mind knowing your vital paperwork is always within reach."
                />
                {isMobile() &&
                    featureIndex == 3 &&
                    getLottieAnimation(featureIndex, true)}
                <ExpandableCard
                    animationTime={13}
                    selected={featureIndex == 4}
                    onClick={() => updateIndex(4)}
                    handleBarFill={() => updateIndex(0)}
                    icon={AssetProvider.contact}
                    title="Contact Directory"
                    subtitle="Access Emergency Contacts and Service Providers"
                    description="Store and share emergency contacts effortlessly with Homie. Have essential contacts readily available for peace of mind and swift access when needed."
                />
                {isMobile() &&
                    featureIndex == 4 &&
                    getLottieAnimation(featureIndex, true)}
            </div>
            {!isMobile() && getLottieAnimation(featureIndex, false)}
        </div>
    );
};
