import Spacer from "../../../../../components/spacer";
import AssetProvider from "../../../../../constants/app-assets";
import { isMobile } from "../../../../../utils/helper-functions";
import "./impact-header.css";

export default function ImpactHeader() {
    return (
        <div id="impact-header">
            <span id="impact-title">
                Welcome to Homie: Your Family's Digital Home!
            </span>
            <div style={{ position: "relative" }}>
                <img
                    style={{
                        position: "absolute",
                        top: isMobile() ? "0px" : "-10px",
                        right: "10vw",
                        height: isMobile() ? "8px" : "auto",
                        width: isMobile() ? "40%" : "auto",
                    }}
                    src={AssetProvider.familyLine2}
                    alt="family-underline"
                />
            </div>
            <Spacer height="24px" />
            <p id="impact-para">
                In today's fast-paced world, staying connected and organized as
                a family can be challenging. That's why we created Homie – a
                revolutionary platform designed specifically for families and
                close circles.
            </p>
        </div>
    );
}
