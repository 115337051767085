import { LocalStoreKeys } from "../constants/local-storage-keys";
import { DeviceType } from "../enums/DeviceType.enum";
import appFirebaseService from "../logic/external/app-firebase.service";

export default class Helper {
    static addInteration(interationType: string) {
        const uid = localStorage.getItem(LocalStoreKeys.ANALYTICS_USER_ID);
        if (uid) {
            appFirebaseService.addUserInteration(uid, interationType);
        }
    }

    static validateEmail(email: string): boolean {
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        const emailValidated: boolean = email.length === 0 || !re.test(email);
        return !emailValidated;
    }

    static secretEmail(email: string): string {
        if (email.length === 0) return "";
        const [username, domain] = email.split("@");
        const secretUser: string =
            username.substring(0, 2) + "*".repeat(username.length - 2);
        return `${secretUser}@${domain}`;
    }

    static getMobileOperatingSystem(): DeviceType {
        const windowCast: any = window;
        var userAgent =
            navigator.userAgent || navigator.vendor || windowCast.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return DeviceType.Other; // "Windows Phone"
        }

        if (/android/i.test(userAgent)) {
            return DeviceType.Android;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !windowCast.MSStream) {
            return DeviceType.iOS;
        }

        return DeviceType.Desktop;
    }
}

export const isMobile = (): Boolean => {
    return window.innerWidth < 600;
};
