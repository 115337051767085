import React from "react";
import AssetProvider from "../../constants/app-assets";
import "./family-chip.css";
import { isMobile } from "../../utils/helper-functions";

const FamilyChip = () => {
    const chipStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "row",
    };
    return (
        <div className="family-chip">
            <span id="family-chip-text">#1 Family App</span>
            <img
                width={isMobile() ? "28px" : "40px"}
                src={AssetProvider.celebrateGif}
                alt="celebrate"
            />
        </div>
    );
};

export default FamilyChip;
