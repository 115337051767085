type SpacerProps = {
    height?: string;
    width?: string;
    bg?: string;
};
const Spacer = ({ height, width, bg }: SpacerProps) => {
    const spacerStyle: React.CSSProperties = {
        height: height,
        width: width,
        backgroundColor: bg ?? "transparent",
    };
    return <div style={spacerStyle}></div>;
};

export default Spacer;
