import Spacer from "../../../../components/spacer";
import AppAnalyticService from "../../../../logic/external/analytic.service";
import { FeatureRow } from "./components/feature-row";
import { JoinButton } from "./components/join-button";
import "./feature-section.css";

export default function FeatureSection() {
    return (
        <div id="feature-section">
            <div id="feature-section-wrapper">
                <div id="feature-section-title-wrapper">
                    <span id="feature-section-title">
                        Your Ultimate Family Organizer
                    </span>
                    <Spacer height="24px" />
                    <p id="feature-section-subtitle">
                        Simplify communication, manage schedules, and create
                        lasting memories, all with our family-centric platform.
                    </p>
                </div>
                <FeatureRow />
                {/* <JoinButton
                    text="Join Waitlist Now"
                    click={() => {
                        AppAnalyticService.firebaseEventLog("cta_click", {
                            section: "Feature section",
                            cta_name: "Join Waitlist Now",
                        });
                    }}
                /> */}
            </div>
        </div>
    );
}
