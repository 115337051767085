import { createSlice } from "@reduxjs/toolkit";
import { AppConstants } from "../../constants/app-constants";
import AnalyticActions from "../actions/analytics.action";
import { AnalyticsState } from "../types/analytics-state.type";

export const initialAnalyticState: AnalyticsState = {
    uid: "",
    scroll_depth: 0,
    visits: 0,
};
const analyticActions: AnalyticActions = new AnalyticActions();

export const analyticSlice = createSlice({
    name: AppConstants.analytic,
    initialState: initialAnalyticState,
    reducers: {
        addAnalyticsData: (state, action) =>
            analyticActions.addAnalyticsData(state, action),
        updateScrollPercentage: (state, action) =>
            analyticActions.updateScrollPercentage(state, action),
    },
});

export const { addAnalyticsData, updateScrollPercentage } =
    analyticSlice.actions;
export default analyticSlice.reducer;
