import SubscribeForm from "../../../../components/convertkit-form/subscribe-form";
import Spacer from "../../../../components/spacer";
import AssetProvider from "../../../../constants/app-assets";
import { isMobile } from "../../../../utils/helper-functions";
import "./early-bird-banner.css";

export default function EarlyBirdBanner() {
    return (
        <div id="early-bird-banner">
            <div id="early-bird-content-wrapper">
                <span className="early-bird-banner-title">
                    Be Among the First to Experience Homie
                </span>
                <Spacer height="12px" />
                <p className="early-bird-banner-para">
                    Join our waitlist today and embark on a journey to a
                    happier, healthier, and more connected family life.
                </p>
                <Spacer height="36px" />
                <SubscribeForm btnText="Join Waitlist Now" />
                {isMobile() && <Spacer height="250px" />}
            </div>
            <div id="early-bird-img-wrapper">
                <img src={AssetProvider.family2} alt="happy-family" />
            </div>
        </div>
    );
}
