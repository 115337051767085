import { AppUser } from "../../models/user/user.dto";
import { initialUserState } from "../reducers/user.reducer";

export default class UserActions {
    public addUser(state: any, action: { type: string; payload: any }) {
        const user: AppUser = action.payload.user;
        return { ...state, isSignedIn: true, appUser: user };
    }

    public removeUser(state: any, action: { type: string; payload: any }) {
        return initialUserState;
    }
}
