import { useState } from "react";
import ScrollTrack from "../../components/scroll-track";
import "./home-screen.css";
import EarlyBirdBanner from "./sections/early-bird-banner/early-bird-banner";
import FeatureSection from "./sections/features/feature-section";
import FeelSecureSection from "./sections/feel-secure/feel-secure-section";
import FooterSection from "./sections/footer/footer-section";
import HeroSection from "./sections/hero/hero-section";
import ImpactSection from "./sections/impact/impact-section";
import Navbar from "./sections/navbar/navbar";
import FeedbackSection from "./sections/feedback/feedback-section";

export default function HomeScreen() {
    return (
        <>
            <div id="home-screen">
                <Navbar />
                <HeroSection />
                <ImpactSection />
                <FeatureSection />
                <FeelSecureSection />
                <EarlyBirdBanner />
                {/* <FeedbackSection /> */}
                <FooterSection />
                {/* <Auth modal={authModal} setModal={setAuthModal} /> */}
                <ScrollTrack />
            </div>
        </>
    );
}
