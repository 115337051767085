import { isMobile } from "../../../../../utils/helper-functions";
import "./impact-chip.css";

type ImpactChipProps = {
    text: string;
    icon: any;
    textColor: string;
    outlineColor: string;
    bgColor: string;
};

export default function ImpactChip({
    text,
    icon,
    textColor,
    outlineColor,
    bgColor,
}: ImpactChipProps) {
    const chipWrapperStyle: React.CSSProperties = {
        border: `1px solid ${outlineColor}`,
        background: bgColor,
    };
    const bgStyle: React.CSSProperties = {
        backgroundImage: `url(${icon})`,
        backgroundSize: isMobile() ? "20px 20px" : "auto",
    };
    const textStyle: React.CSSProperties = {
        color: textColor,
    };

    return (
        <div style={chipWrapperStyle} className="impact-chip-wrapper">
            <div className="impact-bg" style={bgStyle} />
            <span style={textStyle} className="impact-chip-text">
                {text}
            </span>
        </div>
    );
}
