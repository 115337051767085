import React from "react";
import "./policies-style.css";

export default function PrivacyPolicy() {
    return (
        <div className="section-wrapper">
            <h1 className="heading-1">PRIVACY STATEMENT</h1>
            <p className="para">
                Homie & Co respects your privacy. Homie & Co SAS, with
                headquarters at Ashok Nagar, 202001, Aligarh, U.P., India,
                including its affiliates ("Homie & Co") provides this Privacy
                Statement (the "Statement") to help you understand how Homie &
                Co collects and uses the Personal Information and Non-Personal
                Information (each as defined below) you submit to or which is
                collected by Homie & Co via Homie & Co’s service called HomieApp
                at <a>https://thehomie.app/</a> or through HomieApp mobile
                applications (the "Service"), including any club or forum
                offered through the Service or that is collected when you visit
                the Service. "You" means an individual who accesses or uses the
                Service. This Statement only governs the collection, storage,
                use and disclosure of information collected through the Service.
                It does not apply to information collected by Homie & Co in
                other ways (for example, from third parties) or through other
                Homie & Co websites or services, and it does not modify or alter
                other agreements you may have, or enter later, with Homie & Co.
                For example, if Homie & Co collects information about you from
                third-party sources, the collection, storage, use and disclosure
                of that information will be governed by Homie & Co’s agreement
                with you or the third party source, unless the privacy statement
                of a third party source indicates that this Statement will
                govern Homie & Co’s use of the information that the third party
                source provides to Homie & Co. Also, if you provide Personal
                Information to Homie & Co through other means, such as when you
                call customer service or send Personal Information related to
                promotions or product registrations via postal mail, this
                Statement does not apply to that information. You acknowledge
                and agree that this Statement has the same effect as a written
                consent. By accessing or using the Service or providing
                information to Homie & Co through the Service, you acknowledge
                that you have reviewed the content of this Statement and consent
                to the practices described in this Statement.
            </p>
            <br />
            <h2 className="heading-2">
                1. Information that Homie & Co Collects
            </h2>
            <p className="para">
                When you visit the Service, Homie & Co collects certain Personal
                Information, as well as Non-Personal Information.
            </p>
            <br />
            <h2 className="heading-2">Personal Information</h2>
            <p className="para">
                In this Statement, “Personal Information” means any information
                about an identifiable individual, as further defined under
                applicable laws. Homie & Co collects Personal Information when
                you submit it to the Service (for example when you sign up for
                or when you email invite other members). The Personal
                Information collected may include your name, birth date,
                address, mobile, phone, or fax number, email address, IP
                address, account information (such as account and online
                registration), online activities, and other specific personal
                information. In some cases, if Homie & Co asks you to provide
                Personal Information, the provision of that information will be
                mandatory to participate in a particular activity, service or
                promotion. If you do not provide this Personal Information, your
                participation in that activity, service or promotion may be
                limited or prohibited. For example, if you choose not to provide
                your Personal Information in a required field when you sign-up
                for a club membership, you may not become a club member of Homie
                & Co.
            </p>
            <br />
            <h2 className="heading-2">Non-Personal Information</h2>
            <p className="para">
                Homie & Co also collects certain “Non-Personal Information,”
                which means data in a form that does not include direct or
                indirect association with an identifiable individual, such as
                gender, language, zip code, area code, unique device identifier,
                location and time zone where an Homie & Co product or service is
                used, and information about customer activities on the Service,
                to allow Homie & Co to better understand customer behavior and
                improve Homie & Co’s services, products and advertising. Homie &
                Co collects some of this Non-Personal Information automatically
                when you visit the Service (Ad ID, IDFA, Google Advertising ID).
                If Non-Personal Information is combined with Personal
                Information, the combined information will be treated as
                Personal Information for as long as it remains combined.
            </p>
            <br />
            <h2 className="heading-2">Voluntary Disclosure</h2>
            <p className="para">
                Where you voluntarily disclose additional personal information
                without being prompted by Homie & Co and Homie & Co is not made
                aware that it should take special care in handling such
                information, then Homie & Co will not be held liable for that
                information under this Privacy Statement unless and to the
                extent that Homie & Co is obliged to use and protect such
                information pursuant to and in accordance with applicable law.
            </p>
            <br />
            <h2 className="heading-2">Location Services</h2>
            <p className="para">
                Homie & Co and its partners may collect, use, transmit, process,
                store and share your precise location through GPS, WiFi, or
                wireless network triangulation for the purposes of providing our
                Service. This information may include but is not limited to your
                device ID and name, device type and real-time geographic
                location of your device. You may also be able to submit to Homie
                & Co location data such as “Places,” and other information.
                Homie & Co may also supplement the information it collects with
                information obtained from other companies. Homie & Co does not
                share geographic location data with application providers. By
                enabling or using the location-based services or features (such
                as displaying your phone location, posting Footprints, etc.) and
                applications that depend on location-based information, you
                agree and consent to Homie & Co collecting, using, transmitting,
                processing, storing and sharing information related to your
                account and the devices registered to your account for purposes
                of providing such location-based services or features to you.
                You may withdraw this consent by turning off the "Locate"
                function in the location settings (as applicable) on your
                device. The "Family Map" feature is intended for your personal
                use only to locate the last explicit position of the family
                members. The location-based services are not intended or
                suitable for use as an emergency locator system as the location
                display may be imprecise or not valid anymore.
            </p>
            <br />
            <h2 className="heading-2">2. Homie & Co’s Use of Information</h2>
            <p className="para">
                Homie & Co will only use Personal Information for the purposes
                set out in this Statement, with your consent or as required or
                permitted by law. Consent may be express or implied, and given
                in writing, by using or not using a check-off box,
                electronically, orally, or by your conduct, such as by visiting
                the Service and by using services or products offered through
                the Service. You agree that the consent given electronically
                should have the same effect as that given in writing. In some
                jurisdictions implied consent is not sufficient, in which case,
                express consent will be required.
            </p>
            <br />
            <p className="para">
                Homie & Co processes and uses the Personal Information it
                collects for the following purposes:
            </p>
            <br />
            <ul>
                <li>to enable the features you are using;</li>
                <li>
                    to operate, develop and improve its web sites, business,
                    products, services, content and advertising;
                </li>
                <li>
                    to inform you of Homie & Co’s latest product announcements,
                    software updates and services
                </li>
                <li>to provide you with more effective customer service</li>
                <li>
                    to analyze Homie & Co’s business, products and other
                    services
                </li>
                <li>
                    to detect fraud and abuse, and as otherwise described in the
                    Service Terms and Conditions, and if you have an Homie & Co
                    device, your End User License Agreement.
                </li>
            </ul>
            <br />
            <p className="para">
                Homie & Co may also process, access, use, store and disclose
                Personal Information to enforce the terms and policies governing
                your use of the Service (including investigation of any
                potential violation), to detect or prevent security breaches,
                fraudulent activity or technical issues, to protect the rights
                or property of Homie & Co and its customers.
            </p>
            <p className="para">
                Also, Homie & Co may use your Personal Information to
                communicate with you; Homie & Co may send service communications
                such as welcome emails, information on technical service issues,
                and security announcements, as well as information about changes
                to our terms, conditions and policies.
            </p>
            <p className="para">
                Homie & Co may also occasionally send you product surveys or
                promotional mailings to inform you about other products or
                services available from Homie & Co, and to fulfill promotions.
                Personal information collected through the Service may be stored
                and processed in the EEA, USA, India and any other country in
                which Homie & Co or its service providers maintain facilities.
                By using the Service, you consent to the transfer, recording and
                storage of information outside of the country where you reside.
            </p>
            <p className="para">
                While in another jurisdiction for processing, the information
                may be accessed by the courts, law enforcement and national
                security authorities. Homie & Co may use your Personal
                Information from the day you submit it to the day it is deleted,
                whether as a result of deletion due to a request by you to
                delete it, or for another reason.
            </p>
            <p className="para">
                Homie & Co retains your Personal Data until it is no longer
                necessary to provide you with the Service, or until your account
                is deleted.
            </p>
            <br />
            <h2 className="heading-2">3. Sharing Personal Information</h2>
            <p className="para">
                Except as described in this Statement or otherwise as permitted
                by law, Homie & Co’s practice is to not disclose Personal
                Information about you outside of Homie & Co and its affiliates
                without your consent. You hereby consent to the disclosure of
                your Personal Information as outlined in this Statement. Homie &
                Co may disclose the Personal Information described in Section 1
                of this Statement to its third party service providers so they
                may complete transactions or perform services on Homie & Co’s
                behalf, transactions on your behalf, or transactions related to
                providing services to you. For example, Homie & Co may contract
                with third parties to host the Service, collect and provide
                product reviews, provide promotions or surveys, send Homie &
                Co’s emails, and fulfill product orders and promotions.
            </p>
            <p className="para">
                Homie & Co will use contractual agreements to protect Personal
                Information disclosed to third party services providers. Homie &
                Co may also access, use, store and disclose Personal Information
                to comply with the law and lawful requests or legal process,
                enforce the terms and policies governing your use of the Service
                including investigations of any potential violation, to detect
                or prevent security, fraud or technical issues, to protect the
                rights or property of Homie & Co and its customers, and when
                Homie & Co believes in good faith that the disclosure is
                necessary to protect the personal safety of Homie & Co, its
                employees and customers, or the public. Also, Homie & Co may
                disclose Personal Information in connection with (but not
                limited to) the following circumstances:
            </p>
            <ul>
                <li>
                    <strong>Certain Business Transfers.</strong> Homie & Co may
                    disclose personal information as part of a corporate
                    business transaction, such as a merger or acquisition, joint
                    venture, corporate reorganization, financing, or sale of
                    company assets (including for due diligence purposes for a
                    proposed commercial business transaction). Homie & Co may
                    also disclose Personal Information in the event of
                    insolvency, bankruptcy, or receivership in which personal
                    information could be transferred to third parties as one of
                    the business assets in the transaction.
                </li>
                <li>
                    <strong>Promoted Services.</strong> Some services in
                    connection with the Service may be proposed and offered
                    together with another company. If you register for or use
                    these services, both Homie & Co and the other company may
                    receive your Personal Information collected in connection
                    with the promoted services.
                </li>
                <li>
                    <strong>Monitoring and Usage Analysis.</strong> Homie & Co
                    may transfer aggregated data to third party partner to
                    Monitor the effectiveness of our Service and our marketing
                    campaigns, to Monitor aggregate metrics like total number of
                    visitors, users, traffic, and demographic patterns, to
                    Diagnose or fix technology problems reported by our Users or
                    engineers that are associated with certain IP addresses or
                    User IDs.
                </li>
                <li>
                    <strong>Promoted Services.</strong> Some other services may
                    be proposed and offered in the Service by Homie & Co. If you
                    register for or use these Services, you will be bound by the
                    Terms and Privacy Policies of the Promoted Service.
                </li>
                <li>
                    <strong>Monitoring and Usage Analysis</strong> Homie & Co
                    may transfer aggregated data to third party partner to
                    Monitor the effectiveness of our Service and our marketing
                    campaigns, to Monitor aggregate metrics like total number of
                    visitors, users, traffic, and demographic patterns, to
                    Diagnose or fix technology problems reported by our Users or
                    engineers that are associated with certain IP addresses or
                    User IDs.
                </li>
                <li>
                    <strong>Other Purposes.</strong> Homie & Co may disclose
                    Personal Information as consented to by you from time to
                    time, including to fulfill any other purposes that are
                    identified when the Personal Information is collected.
                </li>
            </ul>
            <p className="para">
                Homie & Co will use measures as required by law to protect the
                security of the Personal Information transferred. Some
                jurisdictions only allow disclosure of account information and
                content (including the content of communications) if it is
                provided by law or pursuant to court order, so the above section
                may not apply to you.
            </p>
            <br />
            <h2 className="heading-2">4. Cookies and Other Technologies</h2>
            <p className="para">
                Homie & Co’s websites, services, interactive applications, email
                messages and advertisements may use “cookies” and other
                mechanisms such as pixel tags, Flash cookies and web beacons.
            </p>
            <p className="para">
                A "cookie" is a small data file that the Service transfers to
                your computer for storage to allow Homie & Co’s server to
                “store” or "remember" specific information. These technologies
                help Homie & Co to better understand user behavior and use of
                the Service which allow Homie & Co to analyze trends and also to
                administer the Service. Homie & Co may use "cookies" and other
                tracking mechanisms to help support the integrity of
                registration processes on the Service and to track marketing
                efforts.
            </p>
            <p className="para">
                Homie & Co also allows third party service providers to collect
                information to help Homie & Co track and aggregate individual
                use of the Service. Homie & Co treats most information collected
                by cookies and other technologies as Non-Personal Information.
                However, to the extent that any information is considered
                Personal Information by local law, Homie & Co will treat it as
                Personal Information.
            </p>
            <p className="para">
                Homie & Co and its partners may also use cookies and other
                technologies to remember Personal Information when you use our
                Service, services and applications, in order to retain changes
                that you make and to improve your user experience.
            </p>
            <p className="para">We use the following cookies:</p>
            <ul>
                <li>
                    Strictly necessary cookies: Some cookies are strictly
                    necessary to make our Services available to you; for
                    example, to perform your login functionality and for user
                    authentication and security. We cannot provide you with the
                    Services without this type of cookies;
                </li>
                <li>
                    Functional cookies: These are used to recognize you when you
                    return to our Website. This enables us to personalize our
                    content for you, greet you by name and remember your
                    preferences (for example, your choice of language or
                    region);
                </li>
                <li>
                    Analytical or performance cookies. We also use cookies for
                    analytics purposes in order to operate, maintain, and
                    improve our Services. We use third party analytics
                    providers, including Google Analytics and Firebase, to help
                    us understand how users engage with the Services. Google
                    Analytics and Firebase use first-party cookies to track user
                    interactions which helps show how users use our Services.
                    This information is used to compile reports and to help us
                    improve our Services. The reports disclose Website trends
                    without identifying individual visitors. You can opt out of
                    Google Analytics without affecting how you visit our Website
                    by going to <a>https://tools.google.com/dlpage/gaoptout</a>{" "}
                    or via Google's Ads settings.
                </li>
            </ul>
            <p className="para">
                By providing your contact information and information about your
                device you are able to register your products with Homie & Co
                and enables Homie & Co to provide you with an improved service.
                You do not have to choose to accept cookies and in certain
                jurisdictions cookies that are not strictly necessary to provide
                you with services that you have explicitly requested cannot be
                used without your prior consent, however if you choose not to
                accept all types of cookies certain Service features and
                functionality may no longer work properly, or at all. It may be
                possible to disable some cookies by modifying your browser
                preferences. Please refer to{" "}
                <a>https://www.allaboutcookies.org/</a> for further information
                about cookies and how to disable them.
            </p>
            <br />
            <h2 className="heading-2">5. Children’s Personal Information</h2>
            <p className="para">
                The Service does not target and is not intended for children
                under the age of 13. Homie & Co does not want to collect
                personal information from anyone under the age of 13. If you are
                under 13, you should not access the Service and should not
                provide Homie & Co any information about yourself, including
                your name, address, telephone number, or email address
                (directly, or on site bulletin boards, apps, blogs, etc.). If
                you are 13 years of age or older but under the age of majority
                according to local law (18 in most countries), before you decide
                whether or not to access this Service and to share Personal
                Information with Homie & Co, you should review this Privacy
                Statement with your parent or guardian to make sure that you and
                your parent or guardian understand it. Parents or guardians are
                advised to supervise their children while using the Internet and
                mobile and other devices.
            </p>
            <br />
            <h2 className="heading-2">6. Communication Preferences</h2>
            <p className="para">
                If you have consented to receive marketing communications, Homie
                & Co may send you emails to inform you about products, services
                or other marketing materials, which Homie & Co considers may be
                of interest to you, or that you have otherwise agreed to
                receive. You may opt-out of receiving future marketing
                communications from Homie & Co by following the instructions in
                the relevant communication. If you opt-out of receiving future
                marketing emails, Homie & Co may still send you service
                communications such as (but not limited to) support, software
                and security updates and product recall information.
            </p>
            <br />
            <h2 className="heading-2">
                7. Your rights to access and update to Personal information
            </h2>
            <p className="para">
                You may be able to access, copy, update, and delete your
                Personal Information, to the extent it is not otherwise required
                to be retained by law, through the Service by changing
                registration information you provide in connection with the
                Service. You will be required to sign in with your user name and
                password. Homie & Co may keep records of changes to your
                Personal Information (including deletions). You can delete your
                account via an email request to Homie & Co. Homie & Co will
                respond to your request and stop processing your information 28
                days after receiving such request.
            </p>
            <p className="para">
                <strong>EEA or UK residents.</strong>
            </p>
            <p className="para">
                If you are based in the EEA or the UK, you have certain rights
                in relation to your Information. You will find more information
                below on when which rights can apply. To exercise your rights,
                please contact us (see section 12). Before fulfilling your
                request, we may ask you to provide reasonable Information to
                verify your identity. Please note that there are exceptions and
                limitations to each of these rights, and that while any changes
                you make will be reflected in active user databases instantly or
                within a reasonable period of time, we may retain Information
                for backups, archiving, prevention of fraud and abuse,
                analytics, satisfaction of legal obligations, or where we
                otherwise reasonably believe that we have a legitimate reason to
                do so.
            </p>
            <p className="para">
                In accordance with Regulation (EU) 2016/679 of the European
                Parliament and of the Council of 27 April 2016 (General Data
                Protection Regulation, known as 'GDPR') and Law No. 78-17 of 6
                January 1978 relating to data processing, files, and freedoms,
                you can withdraw your consent to the processing of your Personal
                Data at any time, and you have the rights of access,
                rectification, opposition, and erasure of your Personal Data.
                You can exercise these rights by making a request to the
                following address: <a>hello@thehomie.app</a>. In case of
                reasonable doubt about your identity, Homie & Co reserves the
                right to request a copy of your signed identity document before
                responding to the exercise of your rights.
            </p>
            <p className="para">
                If you reside in France, and believe, after having contacted us,
                that your rights regarding the protection of your Personal Data
                are not being respected, you can file a complaint with the CNIL.
            </p>
            <p className="para">
                <strong>Other rights</strong>
            </p>
            <p className="para">You also have the following rights:</p>
            <ul>
                <li>
                    <strong>Portability.</strong>
                    You have the right to receive a copy of Information we
                    process on the basis of consent or contract in a structured,
                    commonly used and machine-readable format, or to request
                    that such Information is transferred to a third party.
                </li>
                <li>
                    <strong>Correction.</strong>You have the right to correct
                    any Information held about you that is inaccurate.
                </li>
                <li>
                    <strong>Restriction.</strong>
                    You have a right in certain circumstances to stop us
                    processing Information other than for storage purposes.
                </li>
                <li>
                    You have the right to lodge a complaint with a supervisory
                    authority in your country of residence, place of work or
                    where an incident took place.
                </li>
            </ul>
            <br />
            <p className="para">
                <strong>California Privacy Rights.</strong>
            </p>
            <p className="para">
                California law may entitle California residents to certain
                additional protections regarding Personal Information. For
                purposes of this sub section alone,{" "}
                <strong>“Personal Information”</strong> means any information
                that identifies, relates to, describes, is capable of being
                associated with, or could reasonably be linked, directly or
                indirectly, with a particular California resident or household.
            </p>
            <p className="para">
                We collect the categories of Personal Information as described
                in{" "}
                <strong>the section 2 “Homie & Co Use of Information”</strong>{" "}
                above. We collect, use, and disclose Personal Information in the
                ways described above in this Privacy Policy. We do not sell
                Personal Information to third parties. California residents have
                the following rights to the extent granted by applicable law:
            </p>
            <ul>
                <li>
                    To request and receive information regarding your Personal
                    Information we have collected in the past 12 months
                    (including the categories of Personal Information we have
                    collected, the categories of sources of such information,
                    and the purposes for which we have collected such
                    information);
                </li>
                <li>
                    To request and receive information about whether we have
                    disclosed your Personal Information to third parties in the
                    past 12 months (and if so, which categories of information
                    we have disclosed, and which categories of third parties we
                    have disclosed it to);
                </li>
                <li>
                    To request and receive a copy of your Personal Information
                    collected by us in the past 12 months; and
                </li>
                <li>
                    To request specific treatment relating to the processing of
                    Sensitive Personal Information elements described above.
                </li>
                <li>
                    Our timely response to your request that your Personal
                    Information be deleted.
                </li>
            </ul>
            <p className="para">
                We will not discriminate against you if you choose to exercise
                any of these rights. To make any of the above requests, please
                contact us as set forth at the end of this Privacy Policy. We
                may require verification of your identity before further
                processing your request. In certain instances, we may be
                permitted by law to decline some or all of such request,
                including if we determine that our business does not fall within
                the scope of the California statutes. Please note that if you
                make unfounded, repetitive, or excessive requests (as determined
                in our reasonable discretion) to access your Personal
                Information, you may be charged a fee to the extent permitted by
                law.
            </p>
            <br />
            <h2 className="heading-2">8. Security of Personal Information</h2>
            <p className="para">
                Homie & Co is committed to protecting the security of your
                Personal Information. Homie & Co and the third party service
                providers that provide hosting services for the Service use a
                variety of security technologies and administrative procedures
                to help protect your Personal Information from unauthorized
                access, use or disclosure with security measures appropriate to
                the sensitivity of the information. For example, your Personal
                Information is stored on computer systems located in controlled
                facilities with limited access. Homie & Co restricts access to
                the Personal Information to employees who have a need to use the
                Personal Information.
            </p>
            <br />
            <h2 className="heading-2">9. Third Party Services and Services</h2>
            <p className="para">
                The Service may contain links to third-party websites, products
                and services, such as social networking sites and third parties
                who may offer additional products and services through the
                Service. The third parties use of the data they collect will be
                governed by the third parties’ privacy practices. Please review
                these before providing any Personal Information to any third
                party.
            </p>
            <br />
            <h2 className="heading-2">10. International Data Transfers</h2>
            <p className="para">
                All information processed by us may be processed and stored
                anywhere in the world, including, but not limited to, the EEA,
                UK or the United States or other countries, which may have data
                protection laws that are different from the laws where you live.
                If you use our services, you consent to the transfer of your
                information from any country to any other country.
            </p>
            <br />
            <h2 className="heading-2">11. Changes to this Statement</h2>
            <p className="para">
                This Statement is effective as of the effective date at the
                bottom of this Statement. Homie & Co may periodically update
                this Statement to reflect changes in its practices or applicable
                laws or regulations. If Homie & Co updates this Statement, Homie
                & Co will post the updated version of this Statement and it will
                change the “effective date” at the top of this Statement. If the
                updated version of the Statement contains material changes to
                how Homie & Co collects, uses, or shares your personal
                information, Homie & Co will post a prominent notice of the
                updated version on the Service. To the extent permitted by
                applicable law, your continued use of the Service constitutes
                your binding acceptance of the updated Statement.
            </p>
            <br />
            <h2 className="heading-2">12. How To Contact Homie & Co</h2>
            <p className="para">
                If you have any questions, comments, or concerns about this
                Statement or about Homie & Co’s privacy practices, please direct
                your privacy-related comments or questions{" "}
                <a>hello@thehomie.app</a> or to the applicable address below:
            </p>
            <strong>
                Homie & Co
                <br /> Center Point
                <br /> 202001, Aligarh, UP, India
            </strong>
            <p className="para">
                This document has been updated on March 27, 2024
            </p>
        </div>
    );
}
