export default class AnalyticActions {
    public addAnalyticsData(
        state: any,
        action: { type: string; payload: any }
    ) {
        const data = action.payload;
        return { ...state, ...data };
    }

    public updateScrollPercentage(
        state: any,
        action: { type: string; payload: any }
    ) {
        const data = action.payload;
        const { scrollPercentage } = data;
        return { ...state, scrollPercentage };
    }
}
