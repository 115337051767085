import { UpdateSnackbarPayload } from "../types/app-state.type";

export default class AppActions {
    public showAppDialog(state: any, action: { type: string; payload: any }) {
        const showDialog: boolean = action.payload.showDialog;
        return { ...state, showDialog };
    }

    public updateAppSnackbar(
        state: any,
        action: { type: string; payload: UpdateSnackbarPayload }
    ) {
        const showSnackbar: boolean = action.payload.showSnackbar;
        const snackbarText: string = action.payload.snackbarText;
        return { ...state, showSnackbar, snackbarText };
    }
}
