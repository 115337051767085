export class AppStrings {
    static NO_STORY_FOUND: string = "No Story Found";
    static ALREADY_ON_WAITLIST: string = "You’re already on our waitlist!";
    static EMAIL_CONFIRMATION_SNACKBAR_TEXT: string =
        "You’re now part of our waitlist!";
    static AWAITING_EMAIL_CONFIRMATION: string =
        "Email already sent, awaiting confirmation!";
    static WAITLIST_JOIN_SNACKBAR_TEXT: string = "Email sent, please verify!";
    static UNABLE_TO_JOIN: string = "Unable to join waitlist!";
    static DEFAULT_SNACKBAR_TEXT: string = "";
    static FEEDBACK_SUBMIT_SNACKBAR_TEXT: string =
        "Thank you for you feedback!";
    static REQUIRED_FIELDS_SNACKBAR_TEXT: string = "Both fields are required!";
    static LINK_COPIED: string = "Link copied to clipboard!";
}
