import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { apiRequestLockInterceptor } from "./interceptors/lock-interceptor";
import { AuthTokens } from "../models/auth/auth-token.dto";
import { LoggingService } from "../logic/internal/logging.service";
import { AppConstants } from "../constants/app-constants";
import { ErrorCodes } from "../constants/error-codes";
import { RequestHeader } from "../models/request-header";
import { LocalStoreKeys } from "../constants/local-storage-keys";
import { TokenService } from "../logic/internal/auth/token.service";

const environment = process.env.REACT_APP_ENV;
export default class AxiosService {
    private readonly baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    private axiosClient?: AxiosInstance;
    private bearerToken: string = "";
    private retryCount: number = 0;

    private static _instance: AxiosService;

    private constructor() {
        this.init();
    }

    public static getInstance(): AxiosService {
        return this._instance || (this._instance = new this());
    }

    private init() {
        this.resetAxiosClient();
        this.createAxiosClient();
    }

    get getAxiosClient(): AxiosInstance {
        return this.axiosClient ?? this.createAxiosClient();
    }

    private async interceptRequestResponse(response: AxiosResponse) {
        this.retryCount = 0;
        const status: number = response.status;
        const url: string = response.config.url ?? "no url";
        new LoggingService().logActions(
            `Response: ${status} => ${url}`,
            response
        );
        return response;
    }

    private async interceptRequestError(err: any) {
        this.handleErrorLogging(err);

        if (
            err.code == ErrorCodes.AXIOS_TIMEOUT_CODE ||
            err.code == ErrorCodes.AXIOS_NETWORK_ERR_CODE ||
            err.response?.status >= 500
        ) {
            this.retryCount += 1;
            if (AppConstants.API_RETRY_LIMIT < this.retryCount) {
                throw err; // TODO: check if need to throw or return
            }
            const retryRequest = { ...err.config };
            // retryRequest.headers = this.getCreds();

            new LoggingService().logActions(
                `API retry:${this.retryCount} => ${retryRequest.url}`,
                err
            );
            return this.axiosClient?.request(retryRequest);
        }

        this.retryCount = 0;
        if (
            err.response?.status === 401 &&
            !err.config.url.includes("refresh")
        ) {
            // let refreshed: boolean = await authHelper.refresh();
            let refreshed = false;
            if (refreshed) {
                const retryRequest = { ...err.config };
                // retryRequest.headers = this.getCreds();
                return this.axiosClient!.request(retryRequest);
            } else {
            }
        }
        return err;
    }

    private handleErrorLogging(err: AxiosError) {
        // if (
        //     err.code === AXIOS_TIMEOUT_CODE ||
        //     err.code == AXIOS_NETWORK_ERR_CODE
        // ) {
        //     InternetHandlerService.getInstance().connectivityIssue(
        //         BenchmarkStatus.COMPLETE_FAIL
        //     );
        //     const apiErr: BackendError = errorMessageParsing(
        //         TrumpGameCustomErrorEnum.NETWORK_ISSUE
        //     );
        //     store.dispatch(
        //         errorAction(
        //             new ActionPayloadError({
        //                 error: apiErr,
        //             })
        //         )
        //     );
        //     const errConfig: any = {
        //         url: err.config!.url,
        //         errorStack: JSON.stringify(err),
        //     };
        //     console.error(`Network Error Logging: `, errConfig);
        //     return;
        // }
        // InternetHandlerService.getInstance().connectivityIssue(
        //     BenchmarkStatus.STABLE
        // );
        // if (!(environment === "dev" && err.response?.status === 401)) {
        //     const data = err.response?.data as any;
        //     const errConfig: any = {
        //         url: err.config!.url,
        //         errorStack: JSON.stringify(err),
        //     };
        //     if (data) {
        //         errConfig["config"] = data;
        //         errConfig["errorStatus"] = data["errorStatus"];
        //         errConfig["msg"] = data["message"];
        //     }
        //     console.error(`Interceptor Error Logging: `, errConfig);
        // }
    }

    private createAxiosClient(): AxiosInstance {
        const authCreds: AuthTokens | null = new TokenService().getAuthCreds();

        if (authCreds) {
            this.bearerToken = `Bearer ${authCreds.accessToken}`;
        } else {
            this.bearerToken = "";
        }

        const headerObject: RequestHeader = {
            "Content-Type": "application/json",
            "x-device-id": "web",
            "x-app-version": process.env.REACT_APP_TRUMP_BUILD_NUMBER ?? "",
            authorization: this.bearerToken,
        };

        // define config for the axios client
        const newAxiosClient: AxiosInstance = axios.create({
            baseURL: this.baseUrl,
            timeout: 15000, // 15 sec request timeout
            withCredentials: false, // to avoid CORS error
            headers: {
                ...headerObject,
            },
        });

        // add interceptors to axios client
        newAxiosClient.interceptors.request.use(apiRequestLockInterceptor); // queue all requests when client is locked
        newAxiosClient.interceptors.response.use(
            async (response: AxiosResponse) =>
                await this.interceptRequestResponse(response),
            async (err: any) => await this.interceptRequestError
        );

        this.axiosClient = newAxiosClient;
        return newAxiosClient;
    }

    public async setCreds(accessToken: string, refreshToken: string) {
        // this.authCreds = { authorization, refresh };
        const authCred: AuthTokens = {
            accessToken,
            refreshToken,
        };
        localStorage.setItem(
            LocalStoreKeys.AUTH_CREDS,
            JSON.stringify(authCred)
        );

        // update axios clied with new creds
        this.createAxiosClient();
    }

    public resetAxiosClient() {
        this.retryCount = 0;
        this.bearerToken = "";
    }
}
