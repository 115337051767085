import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.reducer";
import analyticsReducer from "./reducers/analytics.reducer";
import appReducer from "./reducers/app.reducer";

const rootReducer = combineReducers({
    user: userReducer,
    analytic: analyticsReducer,
    app: appReducer,
});

export const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
