import logo from "./../assets/imgs/logo.png";
import homie from "./../assets/imgs/homie.png";

// mobile images
import MobFamImg1 from "./../assets/imgs/mobile/mobile-family-img-1.png";
import MobFamImg2 from "./../assets/imgs/mobile/mobile-family-img-2.png";
import MobFamImg3 from "./../assets/imgs/mobile/mobile-family-img-3.png";
import MobFamImg4 from "./../assets/imgs/mobile/mobile-family-img-4.png";

// images
import familyHero1 from "./../assets/imgs/family-hero-1.png";
import familyHero2 from "./../assets/imgs/family-hero-2.png";
import familyHero3 from "./../assets/imgs/family-hero-3.png";
import familyHeroMob1 from "./../assets/imgs/mobile/mobile-family-hero-img-1.png";
import familyHeroMob2 from "./../assets/imgs/mobile/mobile-family-hero-img-2.png";
import familyHeroMob3 from "./../assets/imgs/mobile/mobile-family-hero-img-3.png";
import family2 from "./../assets/imgs/family-img-2.png";
import family3 from "./../assets/imgs/family-img-3.png";
import family4 from "./../assets/imgs/family-img-4.png";
import family5 from "./../assets/imgs/family-img-5.png";
import family6 from "./../assets/imgs/family-img-6.png";

import stars from "./../assets/imgs/stars.png";
import familyLine from "./../assets/imgs/family-line.png";
import familyLine2 from "./../assets/imgs/family-line-2.svg";
import celebrateGif from "./../assets/gifs/celebrate.gif";
import contactGif from "./../assets/gifs/contact.gif";
import trust from "./../assets/icons/trust.svg";
import secure from "./../assets/icons/secure.svg";
import reliable from "./../assets/icons/reliable.svg";
import lock from "./../assets/icons/lock.svg";
import connected from "./../assets/icons/connected.svg";
import timer from "./../assets/icons/timer.svg";
import lock2 from "./../assets/icons/lock-2.svg";
import lock3 from "./../assets/icons/lock-3.svg";
import organized from "./../assets/icons/organized.svg";
import safeVault from "./../assets/icons/safe-vault.svg";
import location from "./../assets/icons/location.svg";
import bill from "./../assets/icons/bill.svg";
import contact from "./../assets/icons/contact.svg";
import task from "./../assets/icons/task.svg";
import tick from "./../assets/icons/tick.svg";
import tick2 from "./../assets/icons/tick-2.svg";
import cancelCircle from "./../assets/icons/cancel-circle.svg";
import androidIcon from "./../assets/icons/android.svg";
import appleIcon from "./../assets/icons/apple.png";
import copyLinkIcon from "./../assets/icons/copy-link.svg";

// lotties
import contactLottie from "./../assets/lottie/contact.json";
import safeVaultLottie from "./../assets/lottie/safe-vault.json";
import tasksLottie from "./../assets/lottie/tasks.json";
import budgetingLottie from "./../assets/lottie/budgeting.json";
import locationLottie from "./../assets/lottie/location.json";
import waitlistLottie from "./../assets/lottie/waitlist.json";
import confettiLottie from "./../assets/lottie/confetti.json";

// avatars
import boy1 from "./../assets/avatars/boy-1.webp";
import boy2 from "./../assets/avatars/boy-2.webp";
import boychild1 from "./../assets/avatars/boy-child-1.webp";
import girl1 from "./../assets/avatars/girl-1.webp";
import girl2 from "./../assets/avatars/girl-2.webp";
import girlChild1 from "./../assets/avatars/girl-child-1.webp";
import girlChild2 from "./../assets/avatars/girl-child-2.webp";
import man1 from "./../assets/avatars/man-1.webp";
import oldMan1 from "./../assets/avatars/old-man-1.webp";
import oldWoman1 from "./../assets/avatars/old-woman-1.webp";
import oldWoman2 from "./../assets/avatars/old-woman-2.webp";
import woman1 from "./../assets/avatars/woman-1.png";
import woman2 from "./../assets/avatars/woman-2.png";
import woman3 from "./../assets/avatars/woman-3.png";

export default class AssetProvider {
    // avatars
    static boy1 = boy1;
    static boy2 = boy2;
    static boychild1 = boychild1;
    static girl1 = girl1;
    static girl2 = girl2;
    static girlChild1 = girlChild1;
    static girlChild2 = girlChild2;
    static man1 = man1;
    static oldMan1 = oldMan1;
    static oldWoman1 = oldWoman1;
    static oldWoman2 = oldWoman2;
    static woman1 = woman1;
    static woman2 = woman2;
    static woman3 = woman3;

    static logo = logo;

    // icons
    static trust = trust;
    static secure = secure;
    static reliable = reliable;
    static lock = lock;
    static connected = connected;
    static timer = timer;
    static lock2 = lock2;
    static lock3 = lock3;
    static organized = organized;
    static safeVault = safeVault;
    static location = location;
    static bill = bill;
    static contact = contact;
    static task = task;
    static tick = tick;
    static tick2 = tick2;
    static cancelCircle = cancelCircle;
    static androidIcon = androidIcon;
    static appleIcon = appleIcon;
    static copyLinkIcon = copyLinkIcon;

    // images
    static familyHero1 = familyHero1;
    static familyHero2 = familyHero2;
    static familyHero3 = familyHero3;
    static familyHeroMob1 = familyHeroMob1;
    static familyHeroMob2 = familyHeroMob2;
    static familyHeroMob3 = familyHeroMob3;
    static family2 = family2;
    static family3 = family3;
    static family4 = family4;
    static family5 = family5;
    static family6 = family6;
    static stars = stars;
    static familyLine = familyLine;
    static familyLine2 = familyLine2;
    static homie = homie;

    // mobile images
    static MobFamImg1 = MobFamImg1;
    static MobFamImg2 = MobFamImg2;
    static MobFamImg3 = MobFamImg3;
    static MobFamImg4 = MobFamImg4;

    // gifs
    static celebrateGif = celebrateGif;
    static contactGif = contactGif;

    // lottie
    static contactLottie = contactLottie;
    static safeVaultLottie = safeVaultLottie;
    static tasksLottie = tasksLottie;
    static budgetingLottie = budgetingLottie;
    static locationLottie = locationLottie;
    static waitlistLottie = waitlistLottie;
    static confettiLottie = confettiLottie;
}
