import { useEffect } from "react";
import AssetProvider from "../../../../../constants/app-assets";
import "./family-component.css";
import Helper, { isMobile } from "../../../../../utils/helper-functions";

export default function FamilyComponent() {
    function setupScrollParallax() {
        const card1 = document.getElementById("family-card-1");
        const card2 = document.getElementById("family-card-2");
        const card3 = document.getElementById("family-card-3");

        window.addEventListener("scroll", () => {
            if (!(card1 && card2 && card3)) return;

            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;

            const factor1 = 0.8;
            const factor2 = 1.6;
            const factor3 = 2.4;

            const checkpoint1 = windowHeight * factor1;
            const checkpoint2 = windowHeight * factor2;
            const checkpoint3 = windowHeight * factor3;
            const endPosition = windowHeight * (factor3 + 0.5);

            if (scrollPosition < checkpoint1) {
                card1.classList.remove("active");
                card1.classList.remove("stick");
                card2.classList.remove("active");
                card2.classList.remove("stick");
                card3.classList.remove("active");
                card3.classList.remove("stick");
                return;
            } else if (
                scrollPosition >= checkpoint1 &&
                scrollPosition <= endPosition
            ) {
                card1.classList.remove("stick");
                card2.classList.remove("stick");
                card3.classList.remove("stick");

                card1.classList.add("active");

                if (scrollPosition >= checkpoint2) {
                    card2.classList.add("active");
                } else {
                    card2.classList.remove("active");
                }

                if (scrollPosition >= checkpoint3) {
                    card3.classList.add("active");
                } else {
                    card3.classList.remove("active");
                }
            } else {
                if (!card1.classList.contains("stick")) {
                    card1.classList.add("stick");
                    card1.classList.remove("active");
                }
                if (!card2.classList.contains("stick")) {
                    card2.classList.add("stick");
                    card2.classList.remove("active");
                }
                if (!card3.classList.contains("stick")) {
                    card3.classList.add("stick");
                    card3.classList.remove("active");
                }
            }
        });
    }

    useEffect(() => {
        if (!isMobile()) {
            setupScrollParallax();
        }
    }, []);

    return (
        <div id="family-component-wrapper">
            <div className="family-card" id="family-card-1">
                <img
                    height={"400px"}
                    width={"578px"}
                    src={
                        isMobile()
                            ? AssetProvider.familyHeroMob1
                            : AssetProvider.familyHero1
                    }
                    alt="family-hero-1"
                />
            </div>
            <div className="family-card" id="family-card-2">
                <img
                    height={"429px"}
                    width={"555px"}
                    src={
                        isMobile()
                            ? AssetProvider.familyHeroMob2
                            : AssetProvider.familyHero2
                    }
                    alt="family-hero-2"
                />
            </div>
            <div className="family-card" id="family-card-3">
                <img
                    height={"375px"}
                    width={"565px"}
                    src={
                        isMobile()
                            ? AssetProvider.familyHeroMob3
                            : AssetProvider.familyHero3
                    }
                    alt="family-hero-3"
                />
            </div>
        </div>
    );
}
