import React from "react";
import "./waitlist-screen-style.css";
import { Link } from "react-router-dom";
import Spacer from "../../components/spacer";
import AppRoutes from "../../constants/routes";
import Helper from "../../utils/helper-functions";
import AssetProvider from "../../constants/app-assets";
import Lottie from "react-lottie-player";
import CopyLink from "./components/copy-link";

export default function WaitlistScreen() {
    const waitlistLottieProvider = () => {
        return (
            <Lottie
                animationData={AssetProvider.waitlistLottie}
                play={true}
                goTo={1}
                speed={1}
                id="waitlist-lottie"
                loop={true}
                key={`waitlist_lottie`}
            />
        );
    };
    // const confettiLottieProvider = () => {
    //     return (
    //         <Lottie
    //             animationData={AssetProvider.confettiLottie}
    //             play={true}
    //             goTo={1}
    //             speed={1}
    //             id="confetti-lottie"
    //             loop={false}
    //             key={`confetti_lottie`}
    //             onComplete={() => {}}
    //         />
    //     );
    // };

    return (
        <div id="waitlist-screen">
            <div id="waitlist-content-wrapper">
                <Link
                    onClick={() => Helper.addInteration("waitlist_icon_click")}
                    to={AppRoutes.home}
                >
                    <img
                        className="waitlist-logo-img"
                        width={"106px"}
                        height={"48px"}
                        src={AssetProvider.homie}
                        alt="logo"
                    />
                </Link>
                <Spacer height="64px" />
                <span id="waitlist-title">Yay, you’re on the waitlist!</span>
                <Spacer height="24px" />
                <span id="waitlist-text" className="">
                    Share the love by spreading the word about Homie with your
                    friends and family. Together, let's simplify life and
                    amplify love.
                </span>
                <Spacer height="48px" />
                <CopyLink />
                <Spacer height="8vh" />
                {/* {confettiLottieProvider()} */}
                {waitlistLottieProvider()}
            </div>

            <div id="waitlist-footer">
                <Spacer height="1px" width="100%" bg="#E3E3EA" />
                <Spacer height="4vh" />
                <div id="policy-row">
                    <span id="copyright-text">Copyright © 2024 Homie</span>
                    <div id="policy-wrapper">
                        <Link
                            onClick={() =>
                                Helper.addInteration("terms_conditions_click")
                            }
                            to={AppRoutes.termsAndConditions}
                        >
                            <span className="footer-text">
                                Terms & Conditions
                            </span>
                        </Link>
                        <Spacer width="35px" />
                        <Link
                            onClick={() =>
                                Helper.addInteration("privacy_ploicy_click")
                            }
                            to={AppRoutes.privacyPolicy}
                        >
                            <span className="footer-text">Privacy Policy</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
