import { useEffect } from "react";
import "./navbar.css";
import Spacer from "../../../../components/spacer";
import AssetProvider from "../../../../constants/app-assets";
import { SignupButton } from "./components/signup-button";
import { useDispatch } from "react-redux";
import {
    showAppDialog,
    updateAppSnackbar,
} from "../../../../redux/reducers/app.reducer";
import { AppStrings } from "../../../../constants/string-constants";
import { LocalStoreKeys } from "../../../../constants/local-storage-keys";
import { isMobile } from "../../../../utils/helper-functions";

type NavbarProps = {
    // setAuthModal: Function;
};

const Navbar = () => {
    const dispatch = useDispatch();
    // Hamburger Logic here
    useEffect(() => {
        // define all elements that are part of mobile navbar
        const hamburger: any = document.querySelector("#hamburger");
        const navMenu = document.querySelector("#navbar-items");
        const sidebar: any = document.querySelector("#mobile-nav");
        const body: any = document.querySelector("#body");
        const navLink = document.querySelectorAll(".nav-link");

        // listeners to open and close mobile navbar
        // hamburger.addEventListener("click", toggleMobileSideMenu); // TODO: uncomment click handler when needed
        navLink?.forEach((n) => n.addEventListener("click", closeMenu));

        function toggleMobileSideMenu() {
            hamburger.classList.toggle("active");
            sidebar.classList.toggle("active");
            body.classList.toggle("scroll-lock");
            navMenu?.classList.toggle("hide");
        }

        function closeMenu() {
            hamburger.classList.remove("active");
            sidebar.classList.remove("active");
            body.classList.remove("scroll-lock");
            navMenu?.classList.remove("hide");
        }
        return () => {
            hamburger.removeEventListener("click", toggleMobileSideMenu);
            navLink?.forEach((n) => n.removeEventListener("click", closeMenu));
        };
    }, []);

    function getDesktopNavbar() {
        return (
            <nav className="hover-container">
                {/* <a href="#hero-section" className="nav-item">
                    <span className="selected-nav-item nav-element standard-text hover-text">
                        Home
                    </span>
                </a> */}
                {/* <a href="#" className="nav-item">
                    <span className="nav-element standard-text hover-text">
                        Family Stories
                    </span>
                </a> */}
                {/* <Spacer width="30px" /> */}
                <SignupButton
                    text="Get Early Access"
                    click={() => {
                        const emailConfirmed = localStorage.getItem(
                            LocalStoreKeys.EMAIL_CONFIRM
                        );
                        if (emailConfirmed && emailConfirmed == "YES") {
                            dispatch(
                                updateAppSnackbar({
                                    showSnackbar: true,
                                    snackbarText:
                                        AppStrings.ALREADY_ON_WAITLIST,
                                })
                            );
                            return;
                        }
                        dispatch(showAppDialog({ showDialog: true }));
                    }}
                />
                <Spacer width="20px" />
            </nav>
        );
    }

    return (
        <div className="nav-wrapper">
            <a href="#hero-section">
                <div id="logo-wrapper">
                    <img
                        className="hover-this"
                        src={AssetProvider.homie}
                        alt="logo"
                    />
                    {/* <span className="logo-text">Homie</span> */}
                </div>
            </a>
            {getDesktopNavbar()}
            <div id="mobile-nav">
                <div id="sidebar-wrapper" className="col">
                    <div className="col link-wrapper">
                        <a href="#hero-section" className="nav-link">
                            <span className="nav-element intro-text">HOME</span>
                        </a>
                    </div>

                    <div className="row sidebar-icon-row">
                        {/* <CircleIcon
                            lightIcon={AppAssets.instaIcon}
                            darkIcon={AppAssets.instaDarkIcon}
                            alt={"insta icon"}
                            onTap={() =>
                                HelperFunctions.openUrl(AppConstants.instaLink)
                            }
                        /> */}
                        <Spacer width="3vw" />
                        {/* <CircleIcon
                            lightIcon={AppAssets.linkedinIcon}
                            darkIcon={AppAssets.linkedinDarkIcon}
                            alt={"linkedin icon"}
                            onTap={() =>
                                HelperFunctions.openUrl(
                                    AppConstants.linkedinLink
                                )
                            }
                        /> */}
                        <Spacer width="3vw" />
                        {/* <CircleIcon
                            lightIcon={AppAssets.behanceIcon}
                            darkIcon={AppAssets.behanceDarkIcon}
                            alt={"behance icon"}
                            onTap={() =>
                                HelperFunctions.openUrl(
                                    AppConstants.behanceLink
                                )
                            }
                        /> */}
                    </div>
                    <Spacer height={"10vh"} />
                </div>
            </div>
            <div id="hamburger">
                <div id="bar-1" className="bar" />
                <div id="bar-2" className="bar" />
                <div id="bar-3" className="bar" />
            </div>
            {isMobile() && (
                <SignupButton
                    text="Get Early Access"
                    click={() => {
                        const emailConfirmed = localStorage.getItem(
                            LocalStoreKeys.EMAIL_CONFIRM
                        );
                        if (emailConfirmed && emailConfirmed == "YES") {
                            dispatch(
                                updateAppSnackbar({
                                    showSnackbar: true,
                                    snackbarText:
                                        AppStrings.ALREADY_ON_WAITLIST,
                                })
                            );
                            return;
                        }
                        dispatch(showAppDialog({ showDialog: true }));
                    }}
                />
            )}
        </div>
    );
};

export default Navbar;
