export class ApiRoutes {
    // Auth
    static GOOGLE_LOGIN_URL: string = "/api/v1/auth/google-login";
    static REFRESH_TOKEN_URL: string = "/api/v1/auth/refresh-tokens";
    static LOGOUT_URL: string = "/api/v1/auth/logout";

    // User
    static GET_USER_DATA_URL: string = "/api/v1/user";
    static UPDATE_USER_DATA_URL: string = "/api/v1/user";
    static SEARCH_USER_DATA_URL: string = "/api/v1/user/email-search";

    // Family story
    static CREATE_FAMILY_STORY_URL: string = "/api/v1/family-story/create";

    // Demographic metadata
    static DEMOGRAPHIC_METADATA_URL: string = "https://ipinfo.io/json";
}
