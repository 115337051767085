export enum LockState {
    JUST_LOCKED = "JUST_LOCKED",
    ALREADY_LOCKED = "ALREADY_LOCKED",
}

export async function apiRequestLockInterceptor(config: any) {
    const apiLockService = APILockService.getInstance();
    if (apiLockService.isLocked(config)) {
        await apiLockService.waitTillUnlocked();
    }
    return config;
}

export default class APILockService {
    private locked: boolean;
    private lockToken: string | null;
    private resolveFunctions: Function[] = [];
    constructor() {
        this.locked = false;
        this.lockToken = null;
    }

    private static _instance: APILockService;
    public static getInstance(): APILockService {
        return this._instance || (this._instance = new this());
    }

    lock = (lockToken: string): LockState => {
        if (this.locked) {
            if (this.lockToken === lockToken) {
                throw new Error("API service has already been locked.");
            } else {
                return LockState.ALREADY_LOCKED;
            }
        }
        this.lockToken = lockToken;
        this.locked = true;
        return LockState.JUST_LOCKED;
    };

    isLocked = (config: any) => {
        if (config.lockToken === this.lockToken) {
            //We do not want to block the API request which placed the lock in first place
            return false;
        }
        return this.locked;
    };

    releaseLock = (lockToken: string) => {
        if (lockToken === this.lockToken) {
            this.locked = false;
            this.resolveWaitPromise();
        }
    };

    private resolveWaitPromise() {
        for (let i = 0; i < this.resolveFunctions.length; i += 1) {
            const currFunction = this.resolveFunctions[i];
            currFunction(null);
        }
        this.resolveFunctions = [];
    }

    waitTillUnlocked = () => {
        return new Promise((resolve, reject) => {
            this.resolveFunctions.push(resolve);

            setTimeout(() => {
                this.locked = false;
                resolve(null);
            }, 60000); // timeout after 1 mins
        });
    };
}
